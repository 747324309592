import api from "../api";

interface ApplyPromocodeSuccessResponse {
  success: true;
  message: string;
  promocode: string;
  procent_deposit: number;
}

interface ApplyPromocodeErrorResponse {
  success: false;
  error: string;
}

type ApplyPromocodeResponse =
  | ApplyPromocodeSuccessResponse
  | ApplyPromocodeErrorResponse;

export const applyPromocode = (
  promocode: string
): Promise<ApplyPromocodeResponse> => {
  return api.request<ApplyPromocodeResponse>(
    "deposit/promocodes/promocodeSelect",
    {
      method: "POST",
      data: { promocode },
    }
  );
};
