import React from "react";
import { Img } from "react-image";
import { SkinTypeSlider } from "../../../utils/types";
import { getSliderItemfon } from "../../../utils/rarityFonColor";

interface CaseImgProps {
  count: number;
  caseImg: string;
  skins?: SkinTypeSlider[];
}

const CARD_WIDTH = 210;
const VISIBLE_CARDS = 7;

export default function CaseImg({ count, caseImg, skins = [] }: CaseImgProps) {
  const getVisibleSkins = () => {
    if (!skins.length) return [];
    const visibleSkins = [];
    for (let i = 0; i < VISIBLE_CARDS; i++) {
      visibleSkins.push(skins[i % skins.length]);
    }
    return visibleSkins;
  };

  return (
    <div className="w-full mb-2 sm:mb-c30 bg-dark1 rounded-20p relative">
      {/* Фоновые затемненные элементы рулетки - отображаются только один раз */}
      {count === 1 && skins.length > 0 && (
        <div className="absolute inset-0 w-full h-full">
          <div className="roulette-wrapper relative flex justify-center w-full h-full m-0 overflow-hidden rounded-20p bg-dark9">
            <div className="flex justify-center">
              {getVisibleSkins().map((skin, index) => (
                <div
                  key={`${skin.id}-${index}`}
                  className="card-start text-center relative dimmed-card-start hidden md:block"
                  style={{ width: `${CARD_WIDTH}px`, flexShrink: 0 }}
                >
                  {getSliderItemfon(skin.rarity)}
                  <img
                    style={{
                      zIndex: 2,
                      marginTop: "45px",
                      position: "relative",
                    }}
                    src={skin.image_url}
                    alt={skin.skin_name}
                    className="gor-img rounded mb-[5px]"
                  />
                  <p className="text-14 text-cases-name font-medium relative z-2">
                    {skin.weapon}
                  </p>
                  <p className="w-48 text-16 text-cust-white font-medium text-center relative z-2 truncate">
                    {skin.skin_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Контейнер для кейсов */}
      <div className="relative z-10 flex items-center justify-evenly w-full min-h-[148px] md:min-h-[280px]">
        {[...Array(count)].map((_, index) => (
          <React.Fragment key={`container-${index}`}>
            <div
              className={`inline-block min-w-c148 h-c148 md:w-c304 md:h-c304 ${
                count === 1 ? "absolute top-0" : ""
              }`}
            >
              <Img
                src={caseImg}
                alt="back"
                className="object-contain w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </div>
            {index < count - 1 && (
              <div className="w-[1px] h-c148 md:h-c262 bg-[#141826]" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
