import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createSelectors } from "../utils/createSelectors";

interface DepositStore {
  walletAddress: string;
  setWalletAddress: (address: string) => void;
}
// Проверяем доступность Redux DevTools
const isReduxDevtoolsAvailable =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__;

export const useDepositStore = createSelectors(
  create<DepositStore>()(
    immer(
      devtools(
        (set) => ({
          walletAddress: "",
          setWalletAddress: (address) =>
            set((state) => {
              state.walletAddress = address;
            }),
        }),
        {
          enabled:
            process.env.NODE_ENV === "development" && isReduxDevtoolsAvailable,
          name: "Profile store",
        }
      )
    )
  )
);
