import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CaseImg from "./CaseImg";
import SliderGorizontal from "./SliderGorizontal";
import { SkinTypeSlider, SkinWine } from "../../../utils/types";
import { getOpenCase } from "../../../api/cases/apiGetOpenCase";
import { useNotification } from "../../../context/NotificationContext";
import { Img } from "react-image";
import MultiSliderBlock from "./MultiSliderBlock";
import { useUserStore } from "../../../zustand/stores/userStore";
import DualButton from "./DualButton";
import { sellSkin } from "../../../api/skins/apiSellSkin";

interface BloksProps {
  skins: SkinTypeSlider[];
  name: string;
  caseImg: string;
  priceCase: number;
  activeAmounts: number[];
  onStateChange: (state: string) => void;
}

export default function SliderBlock({
  skins,
  name,
  priceCase,
  caseImg,
  activeAmounts,
  onStateChange,
}: BloksProps) {
  const [pageStatus, setPageStatus] = useState<string>("WAITING");
  const [loading, setLoading] = useState<boolean>(false);
  const [winSkins, setWinSkins] = useState<
    { skin: string; price: string; id: number }[]
  >([]);
  const [totalWonPrice, setTotalWonPrice] = useState<number | null>(null);
  const [activeButton, setActiveButton] = useState<number>(
    activeAmounts.length > 0 ? Math.min(...activeAmounts) : 1
  );
  const [isFastMode, setIsFastMode] = useState(false);

  const updateBalance = useUserStore((state) => state.updateBalance);
  const symbol = useUserStore.use.getSymbol()();

  const { addNotification } = useNotification();
  const { t } = useTranslation();

  // Следим за изменением activeAmounts и корректируем activeButton если нужно
  useEffect(() => {
    if (!activeAmounts.includes(activeButton) && pageStatus === "WAITING") {
      const newValue =
        activeAmounts.length > 0 ? Math.min(...activeAmounts) : 1;
      setActiveButton(newValue);
    }
  }, [activeAmounts, activeButton, pageStatus]);

  const parseStringToNumber = (value: string | number): number => {
    if (typeof value === "number") return value;
    const cleanedString = value.replace(/[^\d.-]/g, "");
    const number = parseFloat(cleanedString);
    return isNaN(number) ? 0 : number;
  };

  const setNewPageStatus = (status: string) => {
    setPageStatus(status);
    onStateChange(status);
  };

  const handleOpenForClick = async () => {
    if (pageStatus !== "WAITING" || !activeAmounts.includes(activeButton))
      return;

    const selectedAmount = activeButton; // Сохраняем выбранное количество

    try {
      setLoading(true);
      setNewPageStatus("OPEN_REQUEST");

      const caseData: SkinWine = await getOpenCase(name, selectedAmount); // Используем сохраненное значение

      setWinSkins(caseData.data.skins);
      setTotalWonPrice(parseStringToNumber(caseData.data.total_won_price));
      updateBalance(caseData.data.balance);
      setNewPageStatus("SPINNING");
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Failed to open case:", error);
        const errorMessage = error.message || "An unexpected error occurred -1";
        addNotification(errorMessage, "error");
      } else {
        console.error("Failed to open case -2:", error);
      }
      setNewPageStatus("WAITING");
    } finally {
      setLoading(false);
    }
  };

  const handleSellSkins = async () => {
    try {
      setLoading(true);
      const response = await sellSkin(winSkins.map((item) => item.id));
      if (response.success) {
        updateBalance(response.new_balance);
        handleTryAgain();
      }
    } catch (error) {
      let errorMessage = "";
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      console.error("Error selling skins:", error);
      addNotification(errorMessage, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleSpinEnd = useCallback(() => {
    setNewPageStatus("FINISHED");
    setLoading(false);
  }, []);

  const handleFastModeChange = (isfast: boolean) => {
    setIsFastMode(isfast);
  };

  const renderBlock = () => {
    if (pageStatus == "SPINNING" || pageStatus == "FINISHED") {
      if (activeButton === 1) {
        return (
          <SliderGorizontal
            skins={skins}
            winSkin={winSkins[0]}
            onSpinEnd={handleSpinEnd}
            isFastMode={isFastMode}
          />
        );
      } else if (activeButton >= 2 && activeButton <= 5) {
        return (
          <MultiSliderBlock
            count={activeButton}
            skins={skins}
            winSkins={winSkins}
            onSpinEnd={handleSpinEnd}
            isFastMode={isFastMode}
            handleTryAgain={handleTryAgain}
          />
        );
      }
    }
    return <CaseImg count={activeButton} caseImg={caseImg} skins={skins} />;
  };

  const handleTryAgain = () => {
    setNewPageStatus("WAITING");
    // Проверяем доступные кнопки после возврата
    if (!activeAmounts.includes(activeButton)) {
      const newValue =
        activeAmounts.length > 0 ? Math.min(...activeAmounts) : 1;
      setActiveButton(newValue);
    }
  };

  const renderSliderButtons = () => (
    <div className="flex flex-wrap justify-between items-center gap-1.5 md:gap-4 mt-9">
      <button
        className="flex-1 sm:flex-initial order-1 sm:order-1 flex justify-center md:justify-between items-center px-3 md:px-4 py-3 md:py-c13 bg-grey4 hover:bg-grey4 text-dark8 text-13 md:text-14.24 font-bold rounded-30p"
        onClick={handleTryAgain}
      >
        <div className="inline-block w-5 h-5 sm:w-c26 sm:h-c26 mr-1.5">
          <Img
            src="/images/cases/try.svg"
            alt="try again"
            className="object-cover w-full h-full"
            loader={<span className="inline-block w-full h-full" />}
          />
        </div>
        {t("try-again")}
      </button>
      <button
        className="order-3 sm:order-2 w-full sm:w-auto flex justify-center md:justify-between items-center px-3 md:px-4 py-3 md:py-c13 bg-green3 hover:bg-green4 text-dark8 text-13 md:text-14.24 font-bold rounded-30p"
        onClick={handleSellSkins}
        disabled={loading}
      >
        <div className="inline-block w-5 h-5 sm:w-c26 sm:h-c26 mr-1.5">
          {loading ? (
            <div className="w-full h-full border-t-2 border-b-2 border-dark8 rounded-full animate-spin"></div>
          ) : (
            <Img
              src="/images/profile/cart.svg"
              alt="sell"
              className="object-cover w-full h-full"
              loader={<span className="inline-block w-full h-full" />}
            />
          )}
        </div>
        {t("sell-for")} {symbol}
        {totalWonPrice !== null ? totalWonPrice.toFixed(2) : ""}
      </button>
    </div>
  );

  return (
    <>
      {renderBlock()}
      <div className="flex justify-center items-center gap-2">
        {pageStatus == "OPEN_REQUEST" || pageStatus == "SPINNING" ? (
          <div className="flex flex-col justify-center items-center mt-9">
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
            <p className="text-white text-sm mt-2">{t("case-opening")}...</p>
          </div>
        ) : pageStatus == "FINISHED" ? (
          renderSliderButtons()
        ) : (
          <>
            <div className="flex flex-wrap items-center justify-center gap-1 sm:gap-4">
              <div className="order-1 sm:order-1 flex gap-x-0.5 p-c5 border border-dark6 rounded-full text-10 sm:text-14.24 font-bold overflow-x-auto">
                {[1, 2, 3, 4, 5].map((index) => (
                  <button
                    key={index}
                    disabled={!activeAmounts.includes(index)}
                    className={`flex-shrink-0 w-c34 h-c34 sm:w-c52 sm:h-c50 px-0 py-2 sm:px-5 sm:py-c13 rounded-30p krug 
                      ${
                        activeButton === index
                          ? "bg-grey4 text-dark1"
                          : activeAmounts.includes(index)
                          ? "bg-dark5 text-grey4"
                          : "bg-dark1 text-grey5 cursor-not-allowed"
                      }`}
                    onClick={() =>
                      activeAmounts.includes(index) && setActiveButton(index)
                    }
                  >
                    x{index}
                  </button>
                ))}
              </div>

              <button
                disabled={loading || !activeAmounts.includes(activeButton)}
                className="w-full sm:w-auto order-3 sm:order-2 sm:flex-grow-0 flex justify-center sm:justify-between items-center max-h-c30 px-c34 py-4 bg-red4 hover:bg-red5 text-dark3 text-13.20 sm:text-15.28 font-bold rounded-30p disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={handleOpenForClick}
              >
                {t("open-for")} {symbol}
                {(priceCase * activeButton).toFixed(2)}
              </button>

              <div className="order-2 sm:order-3 flex-grow-0">
                <DualButton
                  onModeChange={handleFastModeChange}
                  isFastMode={isFastMode}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
