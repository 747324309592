import React, { useState, useEffect, useCallback, useRef } from "react";
import { Img } from "react-image";
import { changeCurrency } from "../../api/changeCurrency";
import { useNotification } from "../../context/NotificationContext";
import { useUserStore } from "../../zustand/stores/userStore";

const Currency: React.FC = React.memo(() => {
  const { addNotification } = useNotification();
  const userCurrency = useUserStore.use.getCurrency()();
  const updateCurrency = useUserStore.use.updateCurrency();
  const [isDropdownOpenCurrency, setIsDropdownOpenCurrency] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState<string>("USD");
  const updateSymbol = useUserStore.use.updateSymbol();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (userCurrency) {
      setCurrentCurrency(userCurrency);
    }
  }, [userCurrency]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpenCurrency(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDropdownOpenCurrency((prev) => !prev);
  }, []);

  const selectCurrency = useCallback(
    async (currency: "USD" | "RUB") => {
      try {
        const response = await changeCurrency(currency);
        if (response.success) {
          updateCurrency(currency);

          const newSymbol = currency === "USD" ? "$" : "₽";
          updateSymbol(newSymbol);

          window.location.reload();
        } else {
          throw new Error(response.message || "Failed to change currency");
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Error when changing currency:", error);
          const errorMessage =
            error.message ||
            "An unexpected error occurred while changing currency";
          addNotification(errorMessage, "error");
        } else {
          console.error("Unexpected error when changing currency:", error);
        }
      }
    },
    [addNotification, updateCurrency, updateSymbol]
  );

  return (
    <div
      ref={dropdownRef}
      className="inline-block text-14.18 border border-solid border-dark7 rounded-30p p-c14 md:p-3 px-c9 md:px-2.5 cursor-pointer relative"
      onClick={toggleDropdown}
    >
      <div className="flex items-center">
        <div className="inline-block mr-2 w-c18 h-c14 md:w-c22 md:h-4">
          <Img
            src={`/images/deposit/${currentCurrency.toLowerCase()}.svg`}
            alt={`${currentCurrency.toLowerCase()}`}
            className="object-cover w-full h-full"
            loader={<span className="inline-block w-full h-full mr-2" />}
          />
        </div>
        <span className="text-12 font-medium">{currentCurrency}</span>
        <svg
          className={`w-4 h-4 fill-current ml-1.5 md:ml-2.5 ${
            isDropdownOpenCurrency ? "rotate-180" : ""
          }`}
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.0509 4C2.51393 4 2.22694 4.63245 2.58054 5.03656L5.34145 8.1919C5.69006 8.59031 6.30985 8.59031 6.65846 8.1919L9.41938 5.03657C9.77297 4.63245 9.48599 4 8.94901 4H3.0509Z"
            fill="#9B9FB7"
          />
        </svg>
      </div>
      {isDropdownOpenCurrency && (
        <div className="absolute mt-3 md:mt-2 -ml-2 w-24 md:w-24 bg-fon3 shadow-lg rounded-md border border-dark7 z-10">
          <div
            className="flex text-13 py-1 px-2 cursor-pointer hover:bg-grey1"
            onClick={(e) => {
              e.stopPropagation();
              selectCurrency("USD");
            }}
          >
            <div className="inline-block mr-2 w-c18 h-c14 md:w-c22 md:h-4">
              <Img
                src="/images/deposit/usd.svg"
                alt="usd"
                className="object-cover w-full h-full"
                loader={<span className="inline-block w-full h-full mr-2" />}
              />
            </div>
            USD
          </div>
          <div
            className="flex text-13 py-1 px-2 cursor-pointer hover:bg-grey1"
            onClick={(e) => {
              e.stopPropagation();
              selectCurrency("RUB");
            }}
          >
            <div className="inline-block mr-2 w-c18 h-c14 md:w-c22 md:h-4">
              <Img
                src="/images/deposit/rub.svg"
                alt="rub"
                className="object-cover w-full h-full"
                loader={<span className="inline-block w-full h-full mr-2" />}
              />
            </div>
            RUB
          </div>
        </div>
      )}
    </div>
  );
});

export { Currency };
