import { create } from "zustand";
import { Howl } from "howler";

// Объявляем типы для звуков
let sounds: {
  click: Howl;
  startVert: Howl;
  win: Howl;
  winVert: Howl;
} | null = null;

// Функция инициализации звуков
const initializeSounds = () => {
  if (!sounds) {
    sounds = {
      click: new Howl({ src: ["/sounds/click.mp3"], volume: 0.7 }),
      startVert: new Howl({ src: ["/sounds/startVert.mp3"], volume: 0.7 }),
      win: new Howl({ src: ["/sounds/win.mp3"], volume: 0.7 }),
      winVert: new Howl({ src: ["/sounds/winVert.mp3"], volume: 0.7 }),
    };
  }
};

// Получаем начальное состояние из localStorage
const getInitialMuteState = (): boolean => {
  const savedState = localStorage.getItem("soundMuted");
  return savedState ? JSON.parse(savedState) : false;
};

interface SoundStore {
  isMuted: boolean;
  isInitialized: boolean;
  toggleMute: () => void;
  initializeAudio: () => void;
}

export const useSoundStore = create<SoundStore>((set) => ({
  isMuted: getInitialMuteState(),
  isInitialized: false,
  toggleMute: () =>
    set((state) => {
      const newMutedState = !state.isMuted;
      localStorage.setItem("soundMuted", JSON.stringify(newMutedState));
      return { isMuted: newMutedState };
    }),
  initializeAudio: () => {
    initializeSounds();
    set({ isInitialized: true });
  },
}));

export const playSound = {
  click: () => {
    if (!sounds || useSoundStore.getState().isMuted) return;
    sounds.click.play();
  },
  startVert: () => {
    if (!sounds || useSoundStore.getState().isMuted) return;
    sounds.startVert.play();
  },
  win: () => {
    if (!sounds || useSoundStore.getState().isMuted) return;
    sounds.win.play();
  },
  winVert: () => {
    if (!sounds || useSoundStore.getState().isMuted) return;
    sounds.winVert.play();
  },
};
