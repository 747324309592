import React, { useState } from "react";
import { Img } from "react-image";
import { SkinType } from "../../utils/types";
import { getBgColorClass } from "../../utils/rarityFonColor";
import { useUserStore } from "../../zustand/stores/userStore";
import truncateName from "../../utils/truncateName";

interface CasesProps {
  casesItem: SkinType;
  isClicked: boolean;
  onClick: () => void;
}

const CardModalReplace: React.FC<CasesProps> = ({
  casesItem,
  isClicked,
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const bgColorClass = getBgColorClass(casesItem.rarity);
  const symbol = useUserStore.use.getSymbol()();

  return (
    <div
      className={`relative w-full aspect-[108/127] flex flex-col items-center rounded-14p pb-6 px-3 cursor-pointer ${bgColorClass}`}
      style={{ backgroundImage: bgColorClass }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      {isClicked && (
        <Img
          src="/images/profile/rep-check.svg"
          alt="Clicked Image"
          width={22}
          height={22}
          className="absolute top-2.5 left-2.5 z-20"
        />
      )}
      {isHovered && (
        <div className="absolute inset-0 bg-grey6 bg-center rounded-14p z-10 flex justify-center items-center">
          <Img
            src="/images/profile/rep-select.svg"
            alt="hover"
            className="w-[39%] h-auto"
          />
        </div>
      )}
      <span className="absolute text-[10px] text-yellow4 font-semibold top-2.5 right-2.5">
        {symbol}
        {casesItem.price}
      </span>
      <Img
        src={casesItem.image_url}
        alt={casesItem.skin_name}
        className="w-[81%] md:w-[100%] h-auto mt-4"
      />
      <div className="absolute left-2.5 bottom-2.5 text-left">
        <div className="flex gap-[3px] mb-1">
          {casesItem.wear_en && (
            <span className="text-[9.12px] text-grey4 font-semibold border rounded-15p px-1.5 py-0.5">
              {casesItem.wear_en}
            </span>
          )}
          {casesItem.stattrak && (
            <span className="text-[9.12px] text-red9 font-semibold border rounded-15p px-1.5 py-0.5">
              ST
            </span>
          )}
        </div>
        <p className="text-[12px] text-cases-name font-medium text-left">
          {casesItem.weapon}
        </p>
        <p className="text-[13px] text-cust-white font-medium">
          {truncateName(casesItem.skin_name, 10)}
        </p>
      </div>
    </div>
  );
};

export { CardModalReplace };
