import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import GiftCardPay from "./PaymentMethods/GiftCardPay";
import CryptoPay from "./PaymentMethods/CryptoPay";
import WalletsPay from "./PaymentMethods/WalletsPay";
import { PaymentFormProps, DepositMethodNetwork } from "./types";
import { useUserStore } from "../../zustand/stores/userStore";
import { useState, useEffect } from "react";

const PaymentForm: React.FC<PaymentFormProps> = ({
  activeButton,
  depositAmount,
  getUsdAmount,
  giftCode,
  phoneNumber,
  handleDepositChange,
  handleGetUsdChange,
  handleGiftCodeChange,
  handlePhoneNumberChange,
  selectedPayItem,
  isSameCurrency,
  isDepositBelowMin,
  isUsdBelowMin,
  shouldShowUsd,
}) => {
  const symbol = useUserStore.use.getSymbol()();
  const { t } = useTranslation();
  const [selectedNetwork, setSelectedNetwork] =
    useState<DepositMethodNetwork | null>(null);

  useEffect(() => {
    if (selectedPayItem?.networks) {
      const firstNetwork = Object.values(selectedPayItem.networks)[0];
      setSelectedNetwork(firstNetwork);
    } else {
      setSelectedNetwork(null);
    }
  }, [selectedPayItem]);

  const handleNetworkChange = (network: DepositMethodNetwork) => {
    setSelectedNetwork(network);
  };

  const renderPayComponent = () => {
    switch (activeButton) {
      case "Wallets":
        return (
          <WalletsPay
            phoneNumber={phoneNumber}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
        );
      case "Crypto":
        if (selectedPayItem && selectedPayItem.networks) {
          return (
            <CryptoPay
              selectedToken={selectedPayItem}
              depositAmount={depositAmount === null ? null : depositAmount}
              minDepositAmount={selectedPayItem.min}
              onNetworkSelect={handleNetworkChange}
            />
          );
        }
        return null;
      case "Gift Card":
        return (
          <GiftCardPay
            giftCode={giftCode}
            onGiftCodeChange={handleGiftCodeChange}
          />
        );
      default:
        return null;
    }
  };

  const isCardsMethod = activeButton === "Cards";
  const isCryptoMethod = activeButton === "Crypto";

  // Добавляем функцию проверки минимальных значений здесь
  const checkMinValues = () => {
    if (
      isCryptoMethod &&
      selectedNetwork &&
      depositAmount !== null &&
      getUsdAmount !== null
    ) {
      const minTokenValue = parseFloat(selectedNetwork.min_deposit_token);
      const minUsdValue = parseFloat(selectedNetwork.min_deposit_usd);
      return {
        isTokenBelow: depositAmount < minTokenValue,
        isUsdBelow: getUsdAmount < minUsdValue,
      };
    }
    return {
      isTokenBelow: isDepositBelowMin,
      isUsdBelow: isUsdBelowMin,
    };
  };

  const { isTokenBelow, isUsdBelow } = checkMinValues();

  const getLeftFieldCurrencySymbol = () => {
    return selectedPayItem?.currency_symbol || "";
  };

  const getRightFieldCurrencySymbol = () => {
    if (isCardsMethod && selectedPayItem?.currency_symbol === "₽") return "$";
    return symbol;
  };

  const getRightFieldValue = () => {
    if (isCardsMethod || isCryptoMethod) {
      return getUsdAmount === null ? "" : getUsdAmount;
    }
    if (isSameCurrency) {
      return depositAmount === null ? "" : depositAmount;
    }
    return getUsdAmount === null ? "" : getUsdAmount;
  };

  const getMinValues = () => {
    if (isCryptoMethod && selectedPayItem?.networks && selectedNetwork) {
      return {
        minUsd: selectedNetwork.min_deposit_usd,
        minToken: selectedNetwork.min_deposit_token,
      };
    }
    return null;
  };

  const renderMinAmount = () => {
    if (isCryptoMethod && selectedPayItem) {
      const minValues = getMinValues();
      if (minValues) {
        return (
          <>
            {minValues.minUsd}$ ({minValues.minToken} {selectedPayItem.currency}
            )
          </>
        );
      }
    }
    return (
      <>
        {selectedPayItem?.min} {selectedPayItem?.currency}
      </>
    );
  };

  return (
    <div className="flex flex-col w-full gap-y-4 items-start bg-dark4 rounded-14p p-c18 mb-2.5 md:mb-0 mt-2.5 order-1 md:order-3">
      {activeButton !== "Gift Card" && (
        <div className="flex flex-col justify-between gap-c7">
          <p className="text-12 mb-1 ml-3">{t("enter-the-amount")}</p>
          <div className="flex justify-between items-center gap-c7">
            {/* Поле ввода суммы депозита */}
            <div className="flex flex-col gap-1.5 items-start w-full">
              <div className="flex gap-1.5 items-center w-full py-2 px-4 md:py-c13 text-13.18 text-label-text bg-label1 rounded-30p">
                <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
                  <Img
                    src="/images/deposit/swap-left.svg"
                    alt="swap-left"
                    className="object-contain w-full h-full"
                    loader={<span className="inline-block w-full h-full" />}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-11.12 text-grey3">
                    {getLeftFieldCurrencySymbol()}
                  </span>
                  <input
                    type="number"
                    value={depositAmount === null ? "" : depositAmount}
                    onChange={handleDepositChange}
                    placeholder="0"
                    className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-white [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </div>
              </div>
            </div>
            {/* Иконка обмена */}
            <div className="flex-shrink-0 w-c22 h-c22 sm:w-c30 sm:h-c30">
              <Img
                src="/images/deposit/exchange.svg"
                alt="exchange"
                className="object-contain w-full h-full"
                loader={<span className="inline-block w-full h-full" />}
              />
            </div>
            {/* Поле ввода суммы в USD (или другой валюте пользователя) */}
            <div className="flex flex-col gap-1.5 items-start w-full">
              <div className="flex gap-1.5 w-full py-2 px-4 md:py-c13 text-13.18 items-center text-label-text bg-label1 rounded-30p">
                <div className="flex-shrink-0 w-5 h-5 sm:w-c26 sm:h-c26">
                  <Img
                    src={
                      symbol === "₽" &&
                      (!isCardsMethod ||
                        selectedPayItem?.currency_symbol !== "₽")
                        ? "/images/deposit/swap-right-r.svg"
                        : "/images/deposit/swap-right.svg"
                    }
                    alt="swap-right"
                    className="object-contain w-full h-full"
                    loader={<span className="inline-block w-full h-full" />}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-11.12 text-grey3">
                    {getRightFieldCurrencySymbol()}
                  </span>
                  <input
                    type="number"
                    value={getRightFieldValue()}
                    onChange={handleGetUsdChange}
                    placeholder="0"
                    className="w-full bg-label1 focus:outline-none text-12 md:text-15.20 text-white [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {renderPayComponent()}
      <div className="w-full bg-c-grey2 py-2.5 px-4 rounded-19p border border-dotted border-c-grey3">
        <p
          className={`text-13 ${
            isCardsMethod
              ? isTokenBelow
                ? "text-red-500"
                : "text-white"
              : isCryptoMethod
              ? isTokenBelow || isUsdBelow
                ? "text-red-500"
                : "text-white"
              : isTokenBelow || isUsdBelow
              ? "text-red-500"
              : "text-white"
          }`}
        >
          {t("minimum-amount")}{" "}
          <span className="text-white">{renderMinAmount()}</span>
        </p>
      </div>
    </div>
  );
};

export default PaymentForm;
