export default function SecurityPolicy() {
  return (
    // <div className="flex justify-center items-center mt-10 mb-10 text-lg">
    <div className="mt-10 mb-10 max-w-5xl mx-auto p-4 md:p-6 text-grey4">
      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          1. Introduction
        </h2>
        <p className="mb-4">
          Welcome to our CS2 case opening website. We are committed to
          protecting your privacy and providing a secure experience. This
          Privacy Policy explains how we collect, use, disclose, and safeguard
          your information when you use our website and services.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          2. Information We Collect
        </h2>

        <h3 className="text-lg font-semibold mb-3 text-white">
          2.1 Information You Provide
        </h3>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Steam account information (username, Steam ID)</li>
          <li>Contact information (email address)</li>
          <li>
            Payment information (processed through secure third-party payment
            providers)
          </li>
          <li>Trade URLs and Steam inventory information</li>
          <li>Communication data (support tickets, chat messages)</li>
        </ul>

        <h3 className="text-lg font-semibold mb-3 text-white">
          2.2 Information Automatically Collected
        </h3>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Device information</li>
          <li>Operating system</li>
          <li>Time zone and location</li>
          <li>Website usage data (pages visited, time spent, actions taken)</li>
          <li>Cookies and similar tracking technologies</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          3. How We Use Your Information
        </h2>
        <p className="mb-3">We use the collected information for:</p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Providing and maintaining our services</li>
          <li>Processing your transactions and case openings</li>
          <li>Managing your account and verifying your identity</li>
          <li>Sending important notifications about our services</li>
          <li>Preventing fraud and ensuring security</li>
          <li>Improving our website and user experience</li>
          <li>Complying with legal obligations</li>
          <li>Responding to your support requests</li>
          <li>
            Sending service updates and marketing communications (with your
            consent)
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          4. Data Security
        </h2>
        <p className="mb-3">
          We implement appropriate security measures to protect your
          information, including:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>SSL/TLS encryption for data transmission</li>
          <li>Secure storage of personal data</li>
          <li>Regular security assessments</li>
          <li>Access controls for our staff</li>
          <li>Monitoring for potential security breaches</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          5. Third-Party Services
        </h2>

        <h3 className="text-lg font-semibold mb-3 text-white">
          5.1 Payment Processors
        </h3>
        <p className="mb-4">
          We use third-party payment processors who have their own privacy
          policies and security measures. We do not store your complete payment
          information on our servers.
        </p>

        <h3 className="text-lg font-semibold mb-3 text-white">
          5.2 Steam Integration
        </h3>
        <p className="mb-4">
          Our service integrates with Steam for authentication and trading. Your
          use of Steam-related features is subject to Steam's Privacy Policy and
          Terms of Service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          6. Your Rights
        </h2>
        <p className="mb-3">You have the right to:</p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Access your personal information</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Withdraw consent for marketing communications</li>
          <li>Export your data</li>
          <li>Lodge a complaint with supervisory authorities</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          7. Cookie Policy
        </h2>
        <p className="mb-3">We use cookies to:</p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Maintain your session</li>
          <li>Remember your preferences</li>
          <li>Analyze website traffic</li>
          <li>Improve our services</li>
        </ul>
        <p className="mb-4">
          You can control cookie settings through your browser preferences.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          8. Age Restrictions
        </h2>
        <p className="mb-4">
          Our service is not intended for users under 18 years of age. We do not
          knowingly collect information from minors. If you are under 18, please
          do not use our service or provide any personal information.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          9. International Data Transfers
        </h2>
        <p className="mb-4">
          Your information may be transferred to and processed in countries
          other than your own. We ensure appropriate safeguards are in place for
          such transfers.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          10. Changes to Privacy Policy
        </h2>
        <p className="mb-3">
          We may update this Privacy Policy periodically. We will notify you of
          any material changes by:
        </p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Posting the new Privacy Policy on this page</li>
          <li>Sending you an email notification</li>
          <li>Displaying a notice on our website</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          11. Contact Information
        </h2>
        <p className="mb-3">
          For any questions, please contact our support team.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          12. Legal Compliance
        </h2>

        <p className="mb-4">
          We retain your personal information only for as long as necessary to
          provide our services and comply with legal obligations.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          13. Additional Rights for EU Residents
        </h2>
        <p className="mb-3">EU residents have additional rights under GDPR:</p>
        <ul className="list-disc pl-6 mb-4 space-y-2">
          <li>Right to be forgotten</li>
          <li>Right to data portability</li>
          <li>Right to restrict processing</li>
          <li>Right to object to processing</li>
          <li>Right to be informed of data breaches</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          14. Disclaimer
        </h2>
        <p className="mb-4">
          While we take reasonable measures to protect your information, no
          method of transmission over the internet or electronic storage is 100%
          secure. We strive to use commercially acceptable means to protect your
          personal information but cannot guarantee absolute security.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl md:text-2xl font-semibold mb-4 text-white">
          15. Governing Law
        </h2>
        <p className="mb-4">
          This Privacy Policy is governed by and construed in accordance with
          applicable data protection laws.
        </p>
      </section>
    </div>
  );
}
