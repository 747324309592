import { useState } from "react";
import { useTranslation } from "react-i18next";
import Promocode from "./common/Promocode";
import PaymentForm from "./PaymentForm";
import PaymentButton from "./common/PaymentButton";
import { usePaymentLogic } from "./hooks/usePaymentLogic";
import { skinDeposit } from "../../api/deposit/skinDeposit";
import { useNotification } from "../../context/NotificationContext";
import { DepositMethod } from "./types";
import { Img } from "react-image";
import { useUserStore } from "../../zustand/stores/userStore";
import { CardsDeposit } from "../../api/deposit/cardsDeposit";

interface PayProps {
  activeButton: string;
  selectedPayItem: DepositMethod | null;
  activePromocode?: {
    promocode: string;
    procent_deposit: number;
  };
}

const Pay: React.FC<PayProps> = ({
  activeButton,
  selectedPayItem,
  activePromocode,
}) => {
  const { addNotification } = useNotification();
  const { t } = useTranslation();
  const [IsSkinLoading, setIsSkinLoading] = useState<boolean>(false);
  const symbol = useUserStore.use.getSymbol()();
  const isSameCurrency = selectedPayItem?.currency_symbol === symbol;
  const isRubToUsd = selectedPayItem?.currency === "RUB" && symbol === "$";
  const isCardsMethod = activeButton === "Cards";

  // Получаем минимальные значения из первой доступной сети
  const getMinValues = () => {
    if (selectedPayItem?.networks) {
      const firstNetwork = Object.values(selectedPayItem.networks)[0];
      if (firstNetwork) {
        return {
          minUsd: Number(firstNetwork.min_deposit_usd),
          minToken: Number(firstNetwork.min_deposit_token),
        };
      }
    }
    return { minUsd: undefined, minToken: undefined };
  };

  const { minUsd, minToken } = getMinValues();

  const {
    depositAmount,
    getUsdAmount,
    giftCode,
    phoneNumber,
    handleDepositChange,
    handleGetUsdChange,
    handleGiftCodeChange,
    handlePhoneNumberChange,
    isGiftCardButtonActive,
    isWalletsButtonActive,
    isDepositBelowMin,
    isUsdBelowMin,
    shouldShowUsd,
  } = usePaymentLogic(
    selectedPayItem?.min ?? 0,
    Number(selectedPayItem?.rate) || 1,
    isSameCurrency,
    isRubToUsd,
    isCardsMethod,
    activeButton === "Crypto",
    minUsd,
    minToken
  );

  const handleBuySkinClick = async () => {
    try {
      setIsSkinLoading(true);
      const depositLink = await skinDeposit();
      window.open(depositLink, "_blank");
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error receiving link when depositing skin:", error);
        const errorMessage = error.message || "An unexpected error occurred.";
        addNotification(errorMessage, "error");
      } else {
        console.error("Error receiving link when depositing skin -2:", error);
        addNotification("Error receiving link when depositing skin.", "error");
      }
    } finally {
      setIsSkinLoading(false);
    }
  };

  const handleBuyCardsClick = async () => {
    if (
      !selectedPayItem ||
      !selectedPayItem.process_link ||
      !selectedPayItem.type
    ) {
      addNotification("Process link or type is missing", "error");
      return;
    }
    if (depositAmount === null) {
      addNotification("Please enter a valid amount", "error");
      return;
    }

    setIsSkinLoading(true);
    try {
      // Передаем process_link и type в CardsDeposit
      const depositLink = await CardsDeposit(
        depositAmount,
        selectedPayItem.process_link,
        selectedPayItem.type
      );
      window.open(depositLink, "_blank");
    } catch (error) {
      if (error instanceof Error) {
        addNotification(error.message, "error");
      } else {
        addNotification("An unexpected error occurred", "error");
      }
      console.error("Error creating deposit:", error);
    } finally {
      setIsSkinLoading(false);
    }
  };

  const handleBuyWalletsClick = async () => {
    setIsSkinLoading(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    console.log("Simulating Wallets purchase");
    addNotification("Simulated Wallets purchase", "success");
    setIsSkinLoading(false);
  };

  const getClickHandler = () => {
    switch (activeButton) {
      case "Skins":
        return handleBuySkinClick;
      case "Cards":
        return handleBuyCardsClick;
      case "Wallets":
        return handleBuyWalletsClick;
      default:
        return () => console.log("No action for this button");
    }
  };

  const renderSelectedCardInfo = () => {
    if (
      !selectedPayItem ||
      !["Cards", "Wallets", "Crypto", "Skins"].includes(
        selectedPayItem.wallet_type
      )
    ) {
      return null;
    }

    return (
      <div className="w-full bg-dark4 p-4 rounded-lg mt-2.5 md:order-2">
        <div className="flex items-center">
          <Img
            src={selectedPayItem.icon}
            alt={selectedPayItem.name}
            className="w-20 h-12 mr-4 object-contain"
          />
          <div>
            <p className="text-gray-400 text-sm">{t("selected-method")}</p>
            <h3 className="text-white font-semibold">{selectedPayItem.name}</h3>
          </div>
        </div>
      </div>
    );
  };

  const isPaymentButtonActive =
    activeButton === "Gift_card"
      ? isGiftCardButtonActive
      : selectedPayItem !== null;

  return (
    <div className="sticky top-5 self-start flex flex-col items-center md:bg-dark3 md:p-6 rounded-20p">
      <Promocode activePromocode={activePromocode} />
      {renderSelectedCardInfo()}
      {activeButton === "Skins" ? (
        <PaymentButton
          activeButton={activeButton}
          onClick={handleBuySkinClick}
          isActive={isPaymentButtonActive}
          isSkinLoading={IsSkinLoading}
          isDepositBelowMin={isDepositBelowMin}
          isUsdBelowMin={isUsdBelowMin}
        />
      ) : (
        <>
          <PaymentForm
            activeButton={activeButton}
            depositAmount={depositAmount}
            getUsdAmount={getUsdAmount}
            giftCode={giftCode}
            phoneNumber={phoneNumber}
            handleDepositChange={handleDepositChange}
            handleGetUsdChange={handleGetUsdChange}
            handleGiftCodeChange={handleGiftCodeChange}
            handlePhoneNumberChange={handlePhoneNumberChange}
            selectedPayItem={selectedPayItem}
            isSameCurrency={isSameCurrency}
            isDepositBelowMin={isDepositBelowMin}
            isUsdBelowMin={isUsdBelowMin}
            shouldShowUsd={shouldShowUsd}
          />
          {activeButton !== "Crypto" && (
            <PaymentButton
              activeButton={activeButton}
              isGiftCardButtonActive={isGiftCardButtonActive}
              isWalletsButtonActive={isWalletsButtonActive}
              onClick={getClickHandler()}
              isActive={isPaymentButtonActive}
              isSkinLoading={IsSkinLoading}
              isDepositBelowMin={isDepositBelowMin}
              isUsdBelowMin={isUsdBelowMin}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Pay;
