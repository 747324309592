import { Img } from "react-image";
import { PayItemGiftProps } from "../types";

const PayItemGift: React.FC<PayItemGiftProps> = ({ payItem }) => {
  return (
    <div className="relative aspect-[115/80] pt-9 pl-c34 md:pt-c60 md:pl-c65 bg-gift rounded-xl md:rounded-14p cursor-pointer">
      <div className="absolute left-3 top-3 md:left-4 md:top-5">
        <p className="text-12 md:text-20 text-white font-bold">
          {payItem.currency_symbol}
          {payItem.min}
        </p>
        <p className="text-9 md:text-12 text-green8 font-bold">GIFTCARD</p>
      </div>
      <Img
        src="/images/deposit/gift-card-img.svg"
        alt="gift"
        className="object-cover"
      />
    </div>
  );
};

export { PayItemGift };
