import React, { useEffect } from "react";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import { ModalWrapper } from "./utils/modalWrapper";
import { useTranslation } from "react-i18next";

interface Props {
  onSuccess?: () => void;
}

const TelegramAuthModal: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const isTelegramAuthModalOpen = useModalsStore(
    (state) => state.isTelegramAuthModalOpen
  );
  const setIsTelegramAuthModalOpen = useModalsStore(
    (state) => state.setIsTelegramAuthModalOpen
  );

  const handleClose = () => {
    setIsTelegramAuthModalOpen(false);
  };

  useEffect(() => {
    let script: HTMLScriptElement | null = null;
    const telegramLoginButton = document.getElementById(
      "telegram-login-button"
    );

    const cleanup = () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
      if (telegramLoginButton) {
        telegramLoginButton.innerHTML = "";
      }
    };

    if (isTelegramAuthModalOpen && telegramLoginButton) {
      cleanup();

      script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-widget.js?21";
      script.setAttribute("data-telegram-login", "casehousese_bot");
      script.setAttribute("data-size", "large");
      script.setAttribute(
        "data-auth-url",
        "https://testcasehouse.ru/api/user/me/telegram/auth"
      );

      telegramLoginButton.appendChild(script);
    }

    return cleanup;
  }, [isTelegramAuthModalOpen]);

  return (
    <ModalWrapper isOpen={isTelegramAuthModalOpen} onClose={handleClose}>
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors"
        onClick={handleClose}
        aria-label="Close modal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="text-center">
        <p className="text-lg font-semibold mb-4 text-grey5">
          {t("connect-telegram")}
        </p>
        <div id="telegram-login-button" className="flex justify-center"></div>
      </div>
    </ModalWrapper>
  );
};

export default TelegramAuthModal;
