import api from "../api";

interface DepositResponse {
  success: boolean;
  link: string;
}

export async function skinDeposit(): Promise<string> {
  const response = await api.request<DepositResponse>("deposit/steam/skins");
  return response.link;
}
