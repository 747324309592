import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/app/app";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { NotificationProvider } from "./context/NotificationContext";
import { socketService } from "./services/socketService";
import { useUserStore } from "./zustand/stores/userStore";
import { SoundInitializer } from "./components/HOC/SoundInitializer";
import { ReamazeSupport } from "./components/support/Support";

// Инициализируем соединение socket.io
socketService.connect();

// Подписываемся на обновление баланса
socketService.on<{ balance: number }>("balance", (data) => {
  useUserStore.getState().updateBalance(data.balance);
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <SoundInitializer>
    <NotificationProvider>
      <I18nextProvider i18n={i18n}>
        <ReamazeSupport />
        <App />
      </I18nextProvider>
    </NotificationProvider>
  </SoundInitializer>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
