import { StateCreator, create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { createSelectors } from "../utils/createSelectors";
import { UserType } from "../../utils/types";

interface UserStore {
  userData: UserType | null;
  isLoggedIn: boolean;
  needsRedirect: boolean;
  setUserData: (user: UserType) => void;
  reset: () => void;
  getBalance: () => number;
  getSymbol: () => string | null;
  getCurrency: () => string | null;
  updateBalance: (newBalance: number) => void;
  updateCurrency: (newCurrency: string) => void;
  updateSymbol: (newSymbol: string) => void; // Обновление символа валюты
  setNeedsRedirect: (value: boolean) => void;
}

const createUserSlice: StateCreator<
  UserStore,
  [
    ["zustand/immer", never],
    ["zustand/devtools", never],
    ["zustand/persist", unknown]
  ]
> = (set, get) => ({
  userData: null,
  isLoggedIn: false,
  needsRedirect: false,
  setUserData: (user) =>
    set((state) => {
      state.userData = user;
      state.isLoggedIn = user.success;
    }),
  reset: () =>
    set((state) => {
      state.userData = null;
      state.isLoggedIn = false;
    }),
  getBalance: () => {
    const balanceStr = get().userData?.balance;
    return balanceStr ? parseFloat(balanceStr) : 0;
  },
  getSymbol: () => get().userData?.symbol ?? null,
  updateBalance: (newBalance) =>
    set((state) => {
      if (state.userData) {
        state.userData.balance = newBalance.toString(); // Преобразуем число в строку
      }
    }),
  updateCurrency: (newCurrency) =>
    set((state) => {
      if (state.userData) {
        state.userData.currency_selected = newCurrency;
        // console.log("Обновлена валюта пользователя:", newCurrency);
      }
    }),
  updateSymbol: (newSymbol) =>
    set((state) => {
      if (state.userData) {
        state.userData.symbol = newSymbol; // Обновляем символ валюты
      }
    }),
  setNeedsRedirect: (value) => set({ needsRedirect: value }),
  getCurrency: () => get().userData?.currency_selected ?? null,
});

// Проверяем доступность Redux DevTools
const isReduxDevtoolsAvailable =
  typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__;

export const useUserStore = createSelectors(
  create<UserStore>()(
    immer(
      devtools(
        persist(createUserSlice, {
          name: "user",
          partialize: ({ userData, isLoggedIn }) => ({
            userData,
            isLoggedIn,
          }),
        }),
        {
          enabled:
            process.env.NODE_ENV === "development" && isReduxDevtoolsAvailable,
          name: "User store",
        }
      )
    )
  )
);
