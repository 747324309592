import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import React from "react";
import TradeUrl from "./TradeUrl";
import TelegramBonus from "./TelegramBonus";
import StatProfile from "./StatProfile";
import { useUserStore } from "../../zustand/stores/userStore";
import BalanceSection from "./BalanceSection";
import { userLogout } from "../../utils/userLogout";
import { useLocation, useNavigate } from "react-router-dom";

interface ProfileTopProps {}

const ProfileTop: React.FC<ProfileTopProps> = React.memo(() => {
  const [hoveredItem, setHoveredItem] = useState<boolean>(false);
  const { t } = useTranslation();
  const userData = useUserStore.use.userData();
  const userName = userData?.username;
  const userAvatar = userData?.avatar;
  const { reset, setNeedsRedirect } = useUserStore();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    userLogout(location.pathname, setNeedsRedirect, reset, navigate);
  };

  const truncateUserName = (id: string) => {
    return id.length > 16 ? id.slice(0, 14) + ".." : id;
  };

  return (
    <>
      <p className="text-14 lg:text-24 font-medium text-white mb-2.5 lg:mb-4">
        {t("profile")}
      </p>
      <section className="w-full flex flex-col lg:flex-row gap-1.5 md:gap-3 justify-between lg:items-center rounded-2xl mb-2.5 md:mb-6 zoom-p">
        <div className="flex flex-col md:flex-row gap-1.5 md:gap-3">
          <div className="w-full flex flex-row mm-profile">
            <div className="flex flex-grow lg:flex-col items-center justify-center gap-x-2.5 bg-dark1 rounded-14p px-c14 py-c14 lg:px-0 lg:py-c28.5 lg:h-c242">
              <div className="inline-block lg:mb-c14 rounded-full border-none lg:mx-c43 min-w-34 min-h-34 w-c34 h-c34 lg:w-16 lg:h-16">
                <Img
                  src={userAvatar || "/images/profile/avatar.svg"}
                  alt="avatar"
                  className="object-cover w-full h-full rounded-full"
                  loader={
                    <span className="inline-block lg:mb-c14 rounded-full border-none lg:mx-c43 w-full h-full" />
                  }
                />
              </div>

              <p className="text-14 font-medium lg:mb-2.5">
                {userName ? truncateUserName(userName) : ""}
              </p>
              <Img
                src="/images/profile/steam.svg"
                alt="steam"
                width={24}
                height={24}
                className="lg:mb-c22"
              />
              <div
                className="border border-solid border-dark5 rounded-large py-2 px-3 rounded-30p hidden lg:block"
                onMouseEnter={() => setHoveredItem(true)}
                onMouseLeave={() => setHoveredItem(false)}
                onClick={handleLogout}
                style={{ cursor: "pointer" }}
              >
                <Img
                  src={
                    hoveredItem
                      ? "/images/panel-header/exit-hover.svg"
                      : "/images/panel-header/exit.svg"
                  }
                  alt="exit"
                  width={15.9}
                  height={14.62}
                />
              </div>
            </div>
            <div className="flex-grow md:hidden">
              <BalanceSection />
            </div>
          </div>
          <StatProfile userData={userData} />
        </div>

        <div className="w-full flex flex-col md:flex-row gap-2.5 md:gap-3">
          <TradeUrl />
          <TelegramBonus />
        </div>
      </section>
    </>
  );
});

export default ProfileTop;
