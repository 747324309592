import api from "../api";

interface GetSkinSellInfoResponse {
  available_count: number;
  total_price: string;
}

export async function getSellAllSkinsProfile(): Promise<{
  totalPrice: string;
  availableCount: number;
}> {
  const data = await api.request<GetSkinSellInfoResponse>("skins/sell/info");

  return {
    availableCount: data.available_count,
    totalPrice: data.total_price,
  };
}
