import {
  createContext,
  useContext,
  useState,
  useEffect,
  FC,
  ReactNode,
} from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { socketService } from "../services/socketService";
import { WithdrawData } from "../utils/types";

interface NotificationData {
  id: number;
  message: string;
  type: "success" | "error";
  image?: string;
  tradeUrl?: string;
  skinId?: number;
}

interface NotificationContextType {
  notifications: NotificationData[];
  addNotification: (
    messageOrData: string | Omit<NotificationData, "id">,
    type?: "success" | "error"
  ) => void;
  removeNotification: (id: number) => void;
  getTradeUrl: (skinId: number) => string | undefined;
}

const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<NotificationData[]>([]);
  const [tradeUrls, setTradeUrls] = useState<{ [skinId: number]: string }>({});
  const { i18n } = useTranslation();

  const generateId = () => Date.now() + Math.floor(Math.random() * 1000);

  const addNotification = (
    messageOrData: string | Omit<NotificationData, "id">,
    type?: "success" | "error"
  ) => {
    const id = generateId();
    let newNotification: NotificationData;

    if (typeof messageOrData === "string") {
      newNotification = {
        id,
        message: messageOrData,
        type: type || "success",
      };
    } else {
      newNotification = { id, ...messageOrData };
    }

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newNotification,
    ]);

    if (newNotification.skinId && newNotification.tradeUrl) {
      setTradeUrls((prevUrls) => ({
        ...prevUrls,
        [newNotification.skinId!]: newNotification.tradeUrl!,
      }));
    }

    if (!newNotification.image) {
      const timerId = setTimeout(() => {
        removeNotification(id);
        clearTimeout(timerId);
      }, 4000);
    }
  };

  const removeNotification = (id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  };

  const removeNotificationBySkinId = (skinId: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.skinId !== skinId)
    );
    setTradeUrls((prevUrls) => {
      const newUrls = { ...prevUrls };
      delete newUrls[skinId];
      return newUrls;
    });
  };

  const getTradeUrl = (skinId: number) => tradeUrls[skinId];

  useEffect(() => {
    const handleWithdraw = (data: WithdrawData) => {
      const language =
        (Cookies.get("selectedLanguage") as "en" | "ru") || i18n.language;
      const weaponName =
        language === "en" ? data.skin.weapon_en : data.skin.weapon;
      const skinName =
        language === "en" ? data.skin.skin_name_en : data.skin.skin_name;

      if (data.status === "WITHDRAW_SENT") {
        addNotification({
          message: `${weaponName} | ${skinName}`,
          type: "success",
          image: data.skin.image_url,
          tradeUrl: data.tradeURL,
          skinId: data.skin.id,
        });
      } else if (data.status === "WITHDRAW" || data.status === "DEFAULT") {
        removeNotificationBySkinId(data.skin.id);
      }

      // console.log(
      //   `Received status: ${data.status} for skin ID: ${data.skin.id}`
      // );
    };

    socketService.on<WithdrawData>("withdraw", handleWithdraw);

    return () => {
      socketService.off("withdraw", handleWithdraw);
    };
  }, [i18n.language]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
        getTradeUrl,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
