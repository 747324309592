import React, { useState, useEffect } from "react";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import { getBgColorClass } from "../../../utils/rarityFonColor";
import { LiveSkin } from "../../../utils/types";
import truncateName from "../../../utils/truncateName";
import Cookies from "js-cookie";

interface CardHeaderProps {
  cardHeaderItem: LiveSkin;
  hoveredItemId: string | null;
  setHoveredItemId: (id: string | null) => void;
}

const CardHeader: React.FC<CardHeaderProps> = React.memo(
  ({ cardHeaderItem, hoveredItemId, setHoveredItemId }) => {
    const [language, setLanguage] = useState<"en" | "ru">("ru");

    useEffect(() => {
      const storedLanguage = Cookies.get("selectedLanguage") as "en" | "ru";
      setLanguage(storedLanguage || "ru");
    }, []);

    const bgColorClass = getBgColorClass(cardHeaderItem.rarity);

    const skinName =
      language === "en"
        ? cardHeaderItem.skin_name_en
        : cardHeaderItem.skin_name;
    const weaponName =
      language === "en" ? cardHeaderItem.weapon_en : cardHeaderItem.weapon;
    const caseName =
      language === "en"
        ? cardHeaderItem.case_name_en
        : cardHeaderItem.case_name;

    const isHovered = hoveredItemId === cardHeaderItem.uniqueId;

    return (
      <div
        className={`relative flex justify-center flex-shrink-0 md:mr-c5 mr-0.5 w-20 md:w-c140 rounded-14p cursor-pointer ${bgColorClass}`}
        onMouseEnter={() => setHoveredItemId(cardHeaderItem.uniqueId)}
        onMouseLeave={() => setHoveredItemId(null)}
      >
        <div
          className={`flex items-center absolute left-0 transition-all duration-500 ease ml-2.5 ${
            isHovered ? "top-1 md:top-2.5 z-10" : "top-ot-30"
          }`}
        >
          <Link to={`/user/${cardHeaderItem.user_id}`}>
            <div className="w-[22px] h-[22px] md:w-[30px] md:h-[30px] rounded-full overflow-hidden mr-2">
              <Img
                src={cardHeaderItem.user_avatar}
                alt="user avatar"
                className="w-full h-full object-cover"
              />
            </div>
          </Link>
          <p className="text-11 text-cust-white hidden md:block">
            {cardHeaderItem.username}
          </p>
        </div>

        <div
          className={`relative flex w-full h-12 md:h-[112px] flex-col justify-center items-center rounded-14p transition-all duration-1200 ease ${
            isHovered ? "bg-hover-fon1 opacity-85 mt-0 mb-0" : "mt-0 mb-0"
          }`}
        >
          <Img
            src={cardHeaderItem.skin_image}
            alt="item"
            width={110}
            height={80}
            className={`md:w-[110px] md:h-20 w-[66px] h-12 flex flex-col justify-center items-center md:mb-6 ${
              isHovered && "opacity-5"
            }`}
          />

          <div className="absolute bottom-3 left-3 flex flex-col items-start w-full">
            <p
              className={`text-xs font-medium hidden text-cases-name md:block ${
                isHovered && "hidden md:hidden"
              }`}
            >
              {truncateName(weaponName, 15)}
            </p>
            <p
              className={`text-xs font-medium hidden md:block ${
                isHovered && "hidden md:hidden"
              }`}
            >
              {truncateName(skinName, 15)}
            </p>
          </div>

          <Link to={`/case/${cardHeaderItem.name_id}`}>
            <Img
              src={cardHeaderItem.case_image}
              alt="bottomimg"
              width={88}
              height={77}
              className={`absolute right-0 transition-all duration-500 ease ${
                isHovered ? "z-10 top-1 md:top-34" : "top-28"
              }`}
            />
          </Link>

          <div className="blur"></div>

          <div
            className={`flex items-center z-20 justify-center w-auto md:w-c120 rounded-13p border-solid border-white/[0.06] border px-1 md:px-0 ${
              isHovered
                ? "bg-white/[0.04] backdrop-blur-md absolute md:relative bottom-0 mb-0 md:mb-[30px] h-4"
                : "bg-fon1 hidden relative"
            }`}
          >
            <Link to={`/case/${cardHeaderItem.name_id}`}>
              <span className="text-10 z-30 text-blue hidden md:block">
                {truncateName(caseName, 15)}
              </span>
              <span className="text-10 z-30 text-blue md:hidden">
                {truncateName(caseName, 10)}
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
);

export { CardHeader };
