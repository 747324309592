import api from "./api";

interface ChangeCurrencyResponse {
  success: boolean;
  message?: string;
}

export async function changeCurrency(
  currency: "USD" | "RUB"
): Promise<ChangeCurrencyResponse> {
  return api.request<ChangeCurrencyResponse>("user/me/currency_change", {
    method: "POST",
    data: {
      currency: currency,
    },
  });
}
