import { Img } from "react-image";
import { useState } from "react";
import { SkinTypeSlider } from "../../../utils/types";
import { getBgColorClass } from "../../../utils/rarityFonColor";
import truncateName from "../../../utils/truncateName";

interface CasesProps {
  casesItem: SkinTypeSlider;
}

const ItemCard: React.FC<CasesProps> = ({ casesItem }) => {
  const [isHovered, setIsHovered] = useState(false);
  const bgColorClass = getBgColorClass(casesItem.rarity);

  return (
    <div
      className={`relative aspect-[127/101.25] flex flex-col items-center justify-center rounded-14p pb-6 px-3 ${bgColorClass}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Img
        src={casesItem.image_url}
        alt={casesItem.skin_name}
        width={160}
        height={116}
        className="max-w-[80%] transition-transform transform-gpu hover:scale-110"
      />
      <div className="absolute left-3.5 bottom-3.5">
        <p className="text-12 text-cases-name font-medium">
          {isHovered ? casesItem.weapon : truncateName(casesItem.weapon, 18)}
        </p>
        <p className="text-13 text-cust-white font-medium">
          {isHovered
            ? casesItem.skin_name
            : truncateName(casesItem.skin_name, 18)}
        </p>
      </div>
    </div>
  );
};

export { ItemCard };
