import React, { useState } from "react";
import { Img } from "react-image";
import { useTranslation } from "react-i18next";
import { MyCardProfile } from "./myCardProfile/MyCardProfile";
import SwitchBlock from "../filtr/SwitchBlock";
import SkeletonMyCardProfile from "./myCardProfile/SkeletonMyCardProfile";
import { SkinType } from "../../utils/types";
import { getSellAllSkinsProfile } from "../../api/profile/getSellAllSkinsProfile";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import { useProfileStore } from "../../zustand/stores/profileStore";
import { getSkinsForProfile } from "../../api/profile/getSkinsForProfile";
import { useNotification } from "../../context/NotificationContext";
import { Link } from "react-router-dom";

interface ItemsProfileProps {
  setExchangeableSkins: React.Dispatch<React.SetStateAction<SkinType[]>>;
}

const ItemsProfile: React.FC<ItemsProfileProps> = React.memo(
  ({ setExchangeableSkins }) => {
    const { t } = useTranslation();
    const { addNotification } = useNotification();
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [isSellAllLoading, setIsSellAllLoading] = useState(false);

    const setIsReplaceSkinModalOpen =
      useModalsStore.use.setIsReplaceSkinModalOpen();
    const setIsSellSkinModalOpen = useModalsStore.use.setIsSellSkinModalOpen();
    const setIsAllSellSkinModalOpen =
      useModalsStore.use.setIsAllSellSkinModalOpen();

    const skins = useProfileStore.use.skins();
    const loading = useProfileStore.use.loading();
    const hasMore = useProfileStore.use.hasMore();
    const page = useProfileStore.use.page();
    const isDefault = useProfileStore.use.isDefault();
    const skin_count = useProfileStore.use.skin_count();
    const setSkinInfo = useProfileStore.use.setSkinInfo();
    const setTotalPrice = useProfileStore.use.setTotalPrice();
    const setAvailableCount = useProfileStore.use.setAvailableCount();
    const setSkins = useProfileStore.use.setSkins();
    const setLoading = useProfileStore.use.setLoading();
    const setHasMore = useProfileStore.use.setHasMore();
    const setPage = useProfileStore.use.setPage();
    const setIsDefault = useProfileStore.use.setIsDefault();
    const setSkinCount = useProfileStore.use.setSkinCount();

    const [activeButton, setActiveButton] = useState<string>("items");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Функция для переключения состояния чекбокса
    const handleToggle = async () => {
      const newIsDefault = !isDefault;
      setIsDefault(newIsDefault);
      setPage(1);
      setLoading(true);
      try {
        const {
          skins: newSkins,
          hasMore: newHasMore,
          skin_count: newSkinCount,
        } = await getSkinsForProfile(1, newIsDefault);
        setSkins(newSkins, false); // Заменяем существующие скины новыми
        setHasMore(newHasMore);
        setSkinCount(newSkinCount);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Error while getting skins:", error);
          const errorMessage =
            error.message || "An unexpected error occurred -1";
          addNotification(errorMessage, "error");
        } else {
          console.error("Error while getting skins -2:", error);
        }
        setSkins([], false); // В случае ошибки устанавливаем пустой массив
        setHasMore(false);
      } finally {
        setLoading(false);
      }
    };

    // Функция для показа дополнительных карточек
    const handleShowMore = async () => {
      if (!hasMore || loading) return;

      setIsLoadingMore(true);
      try {
        const nextPage = page + 1;
        const {
          skins: newSkins,
          hasMore: newHasMore,
          skin_count: newSkinCount,
        } = await getSkinsForProfile(nextPage, isDefault);
        setSkins([...skins, ...newSkins]); // Объединяем существующие скины с новыми
        setHasMore(newHasMore);
        setPage(nextPage);
        setSkinCount(newSkinCount);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Error when getting additional skins:", error);
          const errorMessage =
            error.message || "An unexpected error occurred -1";
          addNotification(errorMessage, "error");
        } else {
          console.error("Error when getting additional skins -2:", error);
        }
      } finally {
        setIsLoadingMore(false);
      }
    };

    // Функция для открытия модального окна замены скина
    const handleOpenReplaceSkinModal = (
      skinInfo: SkinType,
      exchangeableSkins: SkinType[]
    ) => {
      setSkinInfo(skinInfo);
      setExchangeableSkins(exchangeableSkins);
      setIsReplaceSkinModalOpen(true);
    };

    // Функция для открытия модального окна продажи скина
    const handleOpenSellSkinModal = (skinInfo: SkinType) => {
      setSkinInfo(skinInfo);
      setIsSellSkinModalOpen(true);
    };

    // Функция для открытия модального окна продажи всех скинов
    const handleOpenAllSellSkinModal = async () => {
      try {
        setIsSellAllLoading(true);
        const { totalPrice: price, availableCount: count } =
          await getSellAllSkinsProfile();
        setTotalPrice(price);
        setAvailableCount(count);
        setIsAllSellSkinModalOpen(true);
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(
            "Error while getting information about selling skins:",
            error
          );
          const errorMessage =
            error.message || "An unexpected error occurred -1";
          addNotification(errorMessage, "error");
        } else {
          console.error(
            "Error while getting information about selling skins -2:",
            error
          );
        }
      } finally {
        setIsSellAllLoading(false);
      }
    };

    const messages = {
      upgrade: t("no-upgrade-items"),
      contracts: t("no-contracts-items"),
      default: (
        <>
          <p className="text-18 text-white font-extrabold mb-1">
            {t("no-items-yet")}
          </p>
          <p className="text-14 text-grey3 font-medium mb-c19">
            {t("open-first-case")}
          </p>
          <Link
            to="/"
            className="flex justify-center items-center max-h-c30 px-5 py-c13 mb-2.5 bg-red4 hover:bg-red5 text-dark3 text-13.20 font-bold rounded-30p"
          >
            {t("to-cases")}
            <Img
              src="/images/profile/arrow-right.svg"
              alt="arrow-right"
              width={20}
              height={20}
              className="ml-1.5"
            />
          </Link>
        </>
      ),
    };

    const renderMethodBlock = () => {
      if (activeButton === "upgrade" || activeButton === "contracts") {
        return (
          <div className="w-full min-h-c180 lg:min-h-c232 flex flex-col items-center justify-center border border-dotted border-c-grey3 rounded-2xl">
            <p className="text:14 md:text-18 text-grey3 font-medium">
              {messages[activeButton] || messages.default}
            </p>
          </div>
        );
      }

      if (loading) {
        return (
          <div className="max-w-[1360px] w-full mx-auto">
            <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 gap-1.5 sm:gap-3">
              {Array(28)
                .fill(0)
                .map((_, index) => (
                  <SkeletonMyCardProfile key={index} />
                ))}
            </div>
          </div>
        );
      }

      if (loading && skins.length === 0) {
        return (
          <div className="max-w-[1360px] w-full mx-auto">
            <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 gap-1.5 sm:gap-3">
              {Array(28)
                .fill(0)
                .map((_, index) => (
                  <SkeletonMyCardProfile key={index} />
                ))}
            </div>
          </div>
        );
      }

      if (!skins || skins.length === 0) {
        return (
          <div className="w-full min-h-c180 lg:min-h-c232 flex flex-col items-center justify-center border border-dotted border-c-grey3 rounded-2xl">
            {messages.default}
          </div>
        );
      }

      return (
        <div className="max-w-[1360px] w-full mx-auto">
          <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 gap-1.5 sm:gap-3">
            {skins.map((item, index) => (
              <MyCardProfile
                key={index}
                casesItem={item}
                onOpenReplaceSkinModal={handleOpenReplaceSkinModal}
                onOpenSellSkinModal={handleOpenSellSkinModal}
              />
            ))}
          </div>
        </div>
      );
    };

    const getSkinsCount = () => skin_count;

    const itemMethods = [
      { value: "items", label: "items", count: getSkinsCount() },
      // { value: "upgrade", label: "upgrade", count: 0 },
      // { value: "contracts", label: "contracts", count: 0 },
    ];

    // Функция для изменения активной кнопки
    const handleCheckboxChange = (value: string) => {
      setActiveButton(value);
      setIsDropdownOpen(false);
    };

    // Функция для рендеринга кнопок с чекбоксами
    const renderButtonWithCheckbox = (
      value: string,
      label: string,
      count: number
    ) => (
      <div
        className={`flex items-center justify-between text-12 lg:text-14.16 font-medium p-2 lg:p-3 rounded-30p cursor-pointer mb-0.5 lg:mb-0 ${
          activeButton === value
            ? "bg-dark6 text-grey5"
            : "border border-dark6 text-grey3"
        }`}
        onClick={() => handleCheckboxChange(value)}
      >
        <div className="flex">
          <input
            type="checkbox"
            checked={activeButton === value}
            onChange={() => handleCheckboxChange(value)}
            className="items-checkbox mr-2"
          />
          {t(label)}
        </div>
        <span className="bg-c-grey5 ml-c14 px-2 py-0.5 text-12.18 text-grey5 font-semibold rounded-13p">
          {count}
        </span>
      </div>
    );

    return (
      <>
        <section className="w-full flex justify-between items-center bg-dark4 rounded-2xl gap-1.5 lg:gap-none p-c14 lg:px-5 lg:py-c21 mm-items-profile zoom-menu">
          <div className="w-full lg:w-auto relative flex flex-col items-start lg:flex-row gap-5 lg:items-center">
            <div className="flex items-center">
              <Img
                src="/images/profile/items.svg"
                alt="deposit-logo"
                width={28}
                height={28}
                loader={
                  <span
                    style={{
                      width: 28,
                      height: 28,
                    }}
                    className="mr-2.5"
                  />
                }
                className="mr-2.5"
              />
              <span className="text-14 lg:text-18 text-grey4 font-semibold">
                {t("your-items")}
              </span>
            </div>
            <div
              className="w-full lg:w-auto flex items-center h-c34 lg:h-c52 justify-between gap-1.5 lg:hidden p-2 lg:p-3 border border-dark5 lg:border-none lg:bg-gradient-grey rounded-30p cursor-pointer"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={true}
                  readOnly
                  className="items-checkbox mr-2"
                />
                <span className="text-13.18 lg:text-14.16 font-medium">
                  {t(activeButton)}
                </span>
              </div>
              <span className="bg-c-grey5 px-2 py-0.5 text-12.18 text-grey5 font-semibold rounded-13p">
                {itemMethods.find((method) => method.value === activeButton)
                  ?.count || 0}
              </span>
              <span
                className={`lex-shrink-0 min-w-4 min-h4 transform transition-transform ${
                  isDropdownOpen ? "rotate-180" : ""
                }`}
              >
                <Img
                  src="/images/deposit/st.svg"
                  alt="exchange"
                  width={16}
                  height={16}
                  loader={
                    <span
                      style={{ width: 16, height: 16 }}
                      className="min-w-4 min-h4"
                    />
                  }
                />
              </span>
            </div>
            <div
              className={`absolute mt-c88 lg:mt-0 z-10 bg-dark5 w-c196 p-c14 shadow-custom lg:shadow-none lg:p-0 rounded-26p lg:static lg:border-none lg:bg-transparent lg:flex lg:items-center lg:gap-2 ${
                isDropdownOpen ? "block" : "hidden lg:flex"
              }`}
            >
              {itemMethods.map((method) => (
                <div key={method.value}>
                  {renderButtonWithCheckbox(
                    method.value,
                    method.label,
                    method.count
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="w-full lg:w-auto flex flex-col items-end lg:flex-row gap-5 lg:items-center">
            <div className="flex gap-c11 items-center">
              <p className="text-11 lg:text-13.18 font-medium text-blue">
                {t("available")}
              </p>
              <SwitchBlock isChecked={isDefault} handleToggle={handleToggle} />
            </div>
            <button
              className="w-full lg:w-auto flex justify-center lg:justify-between items-center px-2 lg:px-4 py-2 lg:py-c13 bg-green3 hover:bg-green4 text-dark8 text-12.18 lg:text-14.24 font-bold rounded-30p"
              onClick={handleOpenAllSellSkinModal}
            >
              <div className="inline-block w-4 h-4 lg:w-6 lg:h-6 mr-1.5">
                {isSellAllLoading ? (
                  <div className="w-full h-full border-t-2 border-b-2 border-dark8 rounded-full animate-spin"></div>
                ) : (
                  <Img
                    src="/images/profile/cart.svg"
                    alt="cart"
                    className="object-cover w-full h-full"
                    loader={
                      <span className="inline-block w-full h-full mr-1.5" />
                    }
                  />
                )}
              </div>
              <span className="mr-2">{t("sell-all")}</span>

              {/* <span>
              $
              {loading
                ? ""
                : allSkinsSold
                ? "0.00"
                : (totalPrice ?? 0).toFixed(2)}
            </span> */}
            </button>
          </div>
        </section>
        <section className="w-full flex gap-x-5 rounded-2xl mt-2.5 md:mt-4">
          {renderMethodBlock()}
        </section>
        {isLoadingMore && skins.length > 0 && (
          <div className="max-w-[1360px] w-full mx-auto mt-2.5 md:mt-4">
            <div className="grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 gap-1.5 sm:gap-3">
              {Array(6)
                .fill(0)
                .map((_, index) => (
                  <SkeletonMyCardProfile key={index} />
                ))}
            </div>
          </div>
        )}
        {hasMore && !loading && (
          <div className="flex justify-center mt-2.5 md:mt-4">
            <button
              className="w-full md:w-auto py-2 px-4 border border-dark6 bg-transparent md:bg-dark4 text-15 text-grey5 font-semibold rounded-30p"
              onClick={handleShowMore}
            >
              {t("show-more")}
            </button>
          </div>
        )}
      </>
    );
  }
);

export default ItemsProfile;
