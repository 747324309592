import api from "../api";

interface TelegramResponse {
  success: boolean;
  balance?: string;
  error?: string;
}

const telegramService = {
  async checkSubscription(): Promise<TelegramResponse> {
    return api.request("user/me/telegram/bonus", {
      method: "GET",
    });
  },
};

export { telegramService };
