import React from "react";
import { Img } from "react-image";
import { PayItemSkinProps } from "../types";
import { useTranslation } from "react-i18next";

const PayItemSkin: React.FC<PayItemSkinProps> = ({
  payItem,
  isSelected,
  onSelect,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`relative aspect-[115/80] py-c18 px-c17 md:py-c30 md:px-c23 rounded-xl md:rounded-14p cursor-pointer transition-colors duration-300
        ${
          isSelected
            ? "bg-select-deposit border border-red3 box-border"
            : "bg-label1 hover:bg-hover-deposit"
        }`}
      onClick={() => onSelect(payItem)}
    >
      <span className="absolute top-2.5 left-2.5 text-7 md:text-12 text-white font-bold md:font-semibold bg-bglabel border-1 border-solid border-label2 rounded-20p py-1 px-1.5 md:py-1.5 md:px-2">
        {t(payItem.name)}
      </span>

      {/* Note: We don't have a 'sale' field in the new DepositMethod structure.
          You might want to add this information to your backend response if it's needed. */}
      {/* <span className="absolute top-2.5 right-2.5 text-7 md:text-12 text-white font-bold md:font-semibold bg-bglabel border-1 border-solid border-label2 rounded-20p py-1 px-1.5 md:py-1.5 md:px-2">
        {payItem.sale}
      </span> */}

      {isSelected && (
        <div className="absolute bottom-c7 right-c7 transition-opacity duration-300 opacity-100">
          <Img
            src="/images/deposit/switch.svg"
            alt="Selected"
            width={22}
            height={22}
            className="object-contain"
          />
        </div>
      )}
    </div>
  );
};

export { PayItemSkin };
