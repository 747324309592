import { useTranslation } from "react-i18next";

export default function Support() {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center items-center mt-10 mb-10 text-lg">
      {t("support")}
    </div>
  );
}
