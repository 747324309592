import { SkinType } from "../../utils/types";
import api from "../api";

interface ExchangeSkinsResponse {
  success: boolean;
  skins: SkinType[];
  error?: string;
}

export async function getExchangeSkins(skinId: number): Promise<SkinType[]> {
  try {
    const response = await api.request<ExchangeSkinsResponse>(
      "skins/exchange/list",
      {
        method: "POST",
        data: { user_skin_id: skinId },
      }
    );

    if (response.success && Array.isArray(response.skins)) {
      return response.skins;
    } else {
      throw new Error(response.error || "Failed to get exchange skins");
    }
  } catch (error) {
    throw error;
  }
}
