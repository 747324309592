import React, { useState, useEffect, useCallback } from "react";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoginBlock } from "./LoginBlock";
import { ProfileBlock } from "./ProfileBlock";
import { Menu } from "../Menu";
import { Language } from "./Language";

import { menuItems } from "../../utils/data/menu-items";
import { SocSeti } from "../SocSeti";
import { useUserStore } from "../../zustand/stores/userStore";
import { Currency } from "../deposit/Currency";
import { socketService } from "../../services/socketService";
import { Sound } from "../sound/Sound";

const PanelHeader: React.FC = () => {
  const isLoggedIn = useUserStore.use.isLoggedIn();
  const { t } = useTranslation();
  const [onlineUsers, setOnlineUsers] = useState<number>(0);

  const handleOnlineUpdate = useCallback((data: { online: number }) => {
    if (data && typeof data.online === "number") {
      setOnlineUsers(data.online);
    }
  }, []);

  useEffect(() => {
    socketService.on<{ online: number }>("online", handleOnlineUpdate);

    // Запрашиваем начальное количество пользователей онлайнн
    socketService.emit("get_online_count", null);

    return () => {
      socketService.off("online", handleOnlineUpdate);
    };
  }, [handleOnlineUpdate]);

  return (
    <div
      className={`flex md:flex-col lg:flex-row md:pt-4 lg:pt-0 justify-between items-center md:min-h-c90 min-h-c66 bg-dark4 rounded-2xl mx-0 lg:mx-2.5 pl-4 zoom-h ${
        isLoggedIn ? "pr-4 md:pr-0" : "pr-4"
      }`}
    >
      <div
        className={`flex md:gap-5 gap-8 items-center ${
          isLoggedIn
            ? "md:w-auto md:justify-start"
            : "w-full md:w-auto justify-between md:justify-start"
        }`}
      >
        <Link to="/" className="md:order-1 order-2 md:w-c132 md:h-c51">
          <Img
            src="/images/panel-header/logo-menu.svg"
            alt="panel-logo"
            width={95}
            height={34}
            className="object-cover md:w-c132 md:h-c51"
          />
        </Link>
        <div className="md:order-2 order-1 flex md:gap-c9 gap-c5 items-center text-sm border border-solid border-green1 rounded-14p md:h-16 md:py-3.5 py-c5 md:pl-4 pl-3 md:pr-c18 pr-3">
          <Img
            src="/images/panel-header/online.svg"
            alt="online"
            width={18}
            height={18}
            loader={<span className="object-cover w-c18 h-c18 md:w-7 md:h-7" />}
            className="object-cover md:w-7 md:h-7"
          />
          <div>
            <p className="md:text-16 text-12 text-green2">{onlineUsers}</p>
            <p className="uppercase md:text-11 text-8">{t("online")}</p>
          </div>
        </div>
        <div className="order-3 hidden md:block">
          <Menu menuItems={menuItems} />
        </div>
      </div>

      <div className="flex gap-4 items-center">
        <div className="hidden md:flex gap-4">
          <SocSeti />
        </div>

        <div className="hidden md:block">
          <Language />
        </div>

        {isLoggedIn && (
          <div className="hidden md:block">
            <Currency />
          </div>
        )}

        <div className="hidden md:block w-5 h-5">
          <Sound />
        </div>

        <div className="md:hidden transition-transform duration-3000 transform origin-right">
          {isLoggedIn && <ProfileBlock />}
        </div>

        <div className="hidden justify-end md:block transition-transform duration-3000 transform origin-right">
          {isLoggedIn ? <ProfileBlock /> : <LoginBlock />}
        </div>
      </div>
    </div>
  );
};

export { PanelHeader };
