import React from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

interface UserStatProfileProps {
  casesOpened: number;
  withdrawnToSteam: number;
  skinsDropped: number;
}

const UserStatProfile: React.FC<UserStatProfileProps> = React.memo(
  ({ casesOpened, withdrawnToSteam, skinsDropped }) => {
    const { t } = useTranslation();

    const stats = [
      { icon: "case", value: casesOpened, labelKey: "case-opened" },
      {
        icon: "withdraw",
        value: withdrawnToSteam,
        labelKey: "withdraw-to-steam",
      },
      { icon: "skins", value: skinsDropped, labelKey: "all-skins" },
    ];

    return (
      <div className="w-full flex justify-evenly bg-fon5 py-4 sm:py-6 md:py-c35 rounded-14p">
        {stats.map(({ icon, value, labelKey }) => (
          <div
            key={icon}
            className="flex flex-col sm:flex-row gap-2.5 items-center justify-center px-c17.5"
          >
            <div className="min-w-5 min-h-5 md:w-7 md:h-7">
              <Img
                src={`/images/profile/${icon}.svg`}
                alt={icon}
                className="object-contain w-full h-full"
                loader={<span className="w-full h-full" />}
              />
            </div>
            <div className="text-center sm:text-left">
              <p className="text-12 md:text-22.26 text-grey5 font-bold">
                {value}
              </p>
              <p className="text-9.12 md:text-12 text-grey2 font-medium uppercase">
                {t(labelKey)}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  }
);

export default UserStatProfile;
