import BlokBalance from "./blocks/BlockBalance";
import BlockAuthorized from "./blocks/BlockAuthorized";
import BlockLimit from "./blocks/BlockLimit";
import SliderBlock from "./blocks/SliderBlock";
import { useUserStore } from "../../zustand/stores/userStore";
import { SkinTypeSlider } from "../../utils/types";
import { useState } from "react";

interface CaseBlocksProps {
  skins: SkinTypeSlider[];
  name: string;
  priceCase: number;
  caseImg: string;
  missingBalance: number;
  activeAmounts: number[];
  loading: boolean;
}

export default function CaseBlocks({
  skins,
  name,
  priceCase,
  caseImg,
  missingBalance,
  activeAmounts,
  loading,
}: CaseBlocksProps) {
  const isLoggedIn = useUserStore.use.isLoggedIn();
  const Limit: number = 10;
  const balance = useUserStore.use.getBalance()() ?? 0;
  const [sliderState, setSliderState] = useState<string>("WAITING");

  if (loading) {
    return null;
  }

  // Логика отображения блока баланса:
  // Показываем только если слайдер в состоянии WAITING (после try-again или продажи)
  // и баланса недостаточно для открытия кейса
  const showBalanceBlock = sliderState === "WAITING" && balance < priceCase;

  return (
    <>
      {/* Блок для неавторизованных пользователей */}
      {!isLoggedIn && <BlockAuthorized caseImg={caseImg} skins={skins} />}

      {/* Блок при достижении лимита */}
      {isLoggedIn && Limit === 0 && <BlockLimit caseImg={caseImg} />}

      {/* Блок слайдера (когда можно открывать кейсы или просматривать результаты) */}
      {isLoggedIn && Limit > 0 && !showBalanceBlock && (
        <SliderBlock
          skins={skins}
          name={name}
          priceCase={priceCase}
          caseImg={caseImg}
          activeAmounts={activeAmounts}
          onStateChange={setSliderState}
        />
      )}

      {/* Блок недостаточного баланса (только после try-again или продажи) */}
      {isLoggedIn && Limit > 0 && showBalanceBlock && (
        <BlokBalance
          caseImg={caseImg}
          missingBalance={missingBalance !== undefined ? missingBalance : 0}
          skins={skins}
        />
      )}
    </>
  );
}
