import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated, easings } from "@react-spring/web";
import { SkinTypeSlider } from "../../../utils/types";
import { getSliderVerticalItemfon } from "../../../utils/rarityFonColor";
import { Img } from "react-image";
import { useUserStore } from "../../../zustand/stores/userStore";
import bezier from "bezier-easing";
import truncateRoulette from "../../../utils/truncateRoulette";
import { useIsMobile } from "../../../utils/useIsMobile";

// Константы для размеров компонентов
const CARD_HEIGHT = 140; // Высота одной карточки в рулетке
const CONTAINER_HEIGHT = 280; // Высота видимой области рулетки
const SLIDER_SKINS_COUNT = 40;
const RESULT_CARD_INDEX = 30; // Количество видимых карточек одновременно

const calculateTranslateXFunc = () => {
  const endpoint = -(CARD_HEIGHT * RESULT_CARD_INDEX + CARD_HEIGHT / 2);
  const randomOffset = Math.random() * CARD_HEIGHT * 0.45;
  return { endpoint, random: randomOffset };
};

const shuffleSkins = (array: SkinTypeSlider[]): SkinTypeSlider[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Интерфейс с пропсами компонента
interface SliderVerticalProps {
  skins: SkinTypeSlider[]; // Массив всех доступных скинов
  winSkin: { skin: string; price: string; id: number };
  onSpinEnd: () => void; // Колбэк завершения анимации
  uniqueId: number; // Уникальный ID рулетки
  isFastMode: boolean; // Режим быстрой анимации
  onSellSkin: (skinId: number) => Promise<void>; // Функция продажи скина
  isSold: (skinId: number) => boolean; // Проверка продан ли скин
  isAllSlidersFinished: boolean; // Проверка продан ли скин
  rouletteCount: number; // количество выбранных рулеток
}

const SliderVertical: React.FC<SliderVerticalProps> = ({
  skins,
  onSpinEnd,
  uniqueId,
  isFastMode,
  onSellSkin,
  isSold,
  winSkin,
  isAllSlidersFinished,
  rouletteCount,
}) => {
  const symbol = useUserStore.use.getSymbol()(); // Символ валюты
  const { t } = useTranslation(); // Функция перевода
  const [sliderStatus, setSliderStatus] = useState<string>("SPINNING");
  const [winSkinData, setWinSkinData] = useState<SkinTypeSlider | null>(null);
  const isMobile = useIsMobile(); // Добавляем хук для определения мобильного устройства

  const visibleItems = useMemo(() => {
    let skin_shuffled = shuffleSkins(skins);
    let visibleItems = Array.from(
      { length: SLIDER_SKINS_COUNT },
      (_, i) => skin_shuffled[i % skin_shuffled.length]
    );
    let win_skin = skin_shuffled.find((item) => item.id === winSkin.skin);
    if (win_skin !== undefined) {
      visibleItems[RESULT_CARD_INDEX + 1] = win_skin;
    }

    return visibleItems;
  }, [skins, winSkin]);

  // Устанавливаем `winSkinData` один раз при изменении `winSkin`
  useEffect(() => {
    const win_skin = skins.find((item) => item.id === winSkin.skin);
    if (win_skin) {
      setWinSkinData({
        ...win_skin,
        price: winSkin.price.toString(),
        skin_id: winSkin.id,
      });

      // console.log("winSkinData", winSkinData, skins);
    }
  }, [skins, winSkin]);

  const calculateTranslateX = calculateTranslateXFunc();
  const scrollStyle: any = useSpring({
    from: {
      transform: isFastMode
        ? `translateY(${calculateTranslateX.endpoint}px)`
        : "translateY(0px)",
    },
    to: async (next) => {
      if (sliderStatus == "FINISHED") return;

      // console.log("START ANIMATION", uniqueId);

      if (isFastMode) {
        await next({
          transform: `translateY(${calculateTranslateX.endpoint}px)`,
          config: { duration: 0 },
        });
        setSliderStatus("FINISHED");
        onSpinEnd();
        return;
      } else {
        await next({
          transform: `translateY(${
            calculateTranslateX.endpoint + calculateTranslateX.random
          }px)`,
          config: {
            duration: 5e3 + 600 * uniqueId,
            easing: bezier(0.03, 0.17, 0.09, 1),
          },
        });
        await next({
          transform: `translateY(${calculateTranslateX.endpoint}px)`,
          config: { duration: 800, easing: easings.easeOutSine },
        });

        // console.log("finish animation 2", uniqueId);
        setSliderStatus("FINISHED");
        onSpinEnd();
      }
    },
    // onChange: ({ value }) => {
    //   if (value && value.transform) {
    //     const translateValue = parseFloat(value.transform.match(/-?\d+\.?\d*/)[0]);
    //     if (Math.abs(translateValue) % CONTAINER_HEIGHT === 0) {

    //       console.log('playsound');

    //     }
    //   }
    // },
  });

  const handleSellClick = () => {
    if (winSkinData && winSkinData.skin_id) {
      onSellSkin(winSkinData.skin_id);
    }
  };

  // Рендер компонента
  return (
    <div
      className={`slider-container ${
        sliderStatus == "SPINNING" ? "spinning" : ""
      }`}
    >
      <div
        className="relative w-full overflow-hidden rounded-20p"
        style={{ height: CONTAINER_HEIGHT }}
      >
        {/* Анимированная рулетка */}
        <animated.div
          className="wheel flex flex-col"
          style={Object.assign(
            {},
            {
              backfaceVisibility: "hidden",
              display: isAllSlidersFinished ? "none" : "flex",
              opacity: 1,
              position: "relative",
              width: "100%",
              height: "100%",
            },
            scrollStyle
          )}
        >
          {visibleItems.map((skin, index) => (
            <div
              key={`${skin.id}-${index}`}
              className={`card-vert relative ${
                sliderStatus == "FINISHED" && index != RESULT_CARD_INDEX + 1
                  ? "dimmed-card"
                  : ""
              } ${
                sliderStatus == "FINISHED" && index == RESULT_CARD_INDEX + 1
                  ? ""
                  : ""
              }`}
              style={{ minHeight: CARD_HEIGHT }}
            >
              <div className="fon-img">
                {getSliderVerticalItemfon(skin.rarity)}
              </div>
              <img
                className="img-skin"
                src={skin.image_url}
                alt={skin.skin_name || ""}
              />
            </div>
          ))}
        </animated.div>
        {isAllSlidersFinished && winSkinData != null && winSkinData.skin_id && (
          <div
            className={`win-block-vert win-block-vert-${uniqueId} ${"show px-0.5 md:px-1"} flex flex-col justify-center items-center h-full`}
          >
            <div className="flex justify-center w-full">
              {getSliderVerticalItemfon(winSkinData.rarity)}
            </div>
            <img
              className="img-win z-10"
              src={winSkinData.image_url}
              alt={winSkinData.skin_name}
            />
            <p className="text-9 md:text-13 text-center text-cases-name font-medium mb-0.5">
              {truncateRoulette(winSkinData.weapon, rouletteCount, isMobile)}
            </p>
            <p className="flex items-center justify-center min-h-0 text-9 md:text-14 text-center text-cust-white font-medium mb-1 md:mb-2">
              {truncateRoulette(winSkinData.skin_name, rouletteCount, isMobile)}
            </p>
            <p className="text-10 md:text-13 text-center text-yellow4 font-semibold mb-2">
              {symbol}
              {winSkinData.price}
            </p>
            <button
              className={`sell-button px-3 md:px-c25 py-1.5 md:py-2 bg-green3 hover:bg-green4 text-dark8 text-13 font-bold rounded-30p z-10 ${
                isSold(winSkinData.skin_id)
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleSellClick}
              disabled={isSold(winSkinData.skin_id)}
            >
              <span className="hidden md:block">{t("sell")}</span>
              <span className="md:hidden">
                <Img
                  src="/images/profile/cart.svg"
                  alt="cart"
                  width={18}
                  height={18}
                  loader={
                    <span
                      style={{
                        width: 18,
                        height: 18,
                      }}
                    />
                  }
                />
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SliderVertical;
