import { Img } from "react-image";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { sellSkin } from "../../api/skins/apiSellSkin";
import { useUserStore } from "../../zustand/stores/userStore";
import { useProfileStore } from "../../zustand/stores/profileStore";
import { ModalWrapper } from "./utils/modalWrapper";
import { useNotification } from "../../context/NotificationContext";

const SellSkinModal = () => {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const updateBalance = useUserStore((state) => state.updateBalance);
  const symbol = useUserStore.use.getSymbol()();
  const isSellSkinModalOpen = useModalsStore(
    (state) => state.isSellSkinModalOpen
  );
  const setIsSellSkinModalOpen = useModalsStore(
    (state) => state.setIsSellSkinModalOpen
  );
  const sellSkinInfo = useProfileStore((state) => state.skinInfo);

  const addSoldSkin = useProfileStore((state) => state.addSoldSkin);

  const handleClose = () => setIsSellSkinModalOpen(false);

  const handleSellSkin = async () => {
    if (!sellSkinInfo) return;

    setIsLoading(true);
    setError(null);
    try {
      const response = await sellSkin(sellSkinInfo.id);
      if (response.success) {
        updateBalance(response.new_balance);
        addSoldSkin(sellSkinInfo.id);
        setIsSellSkinModalOpen(false);
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error("Error selling skin:", error);
        const errorMessage = error.message || "An unexpected error occurred.";
        setError(errorMessage || "An unexpected error occurred");
        addNotification(errorMessage, "error");
      } else {
        console.error("Error selling skin -2:", error);
      }
      setTimeout(() => {
        setIsSellSkinModalOpen(false);
      }, 2000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper isOpen={isSellSkinModalOpen} onClose={handleClose}>
      <button
        className="absolute top-6 right-6 text-gray-500"
        onClick={handleClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 20 20"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="w-full flex justify-center mb-4 max-w-228 m-auto">
        <Img
          src={sellSkinInfo?.image_url || ""}
          alt={sellSkinInfo?.skin_name || "Skin"}
          width="100%"
          height={150}
          className="object-contain"
        />
      </div>
      <p className="text-base font-semibold text-blue">
        {sellSkinInfo?.weapon}
      </p>
      <p className="text-13.18 font-medium text-grey3">
        {sellSkinInfo?.skin_name}
      </p>
      <p className="text-15 font-semibold text-yellow4 mt-1">
        {symbol}
        {sellSkinInfo?.price}
      </p>
      <p className={`text-15.20 mt-6 ${error ? "text-red-500" : "text-blue"}`}>
        {error || t("sale-confirmation")}
      </p>
      <div className="flex items-center justify-center gap-2 mt-6">
        <button
          className="flex justify-between items-center px-c18 py-2 md:px-9 md:py-c17 text-grey4 text-12.18 md:text-15.26 font-bold border border-dark5 rounded-30p"
          onClick={handleClose}
          disabled={isLoading}
        >
          {t("cancel")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-c18 h-c18 md:h-c26 md:w-c26 ml-2"
            fill="none"
            viewBox="0 0 26 26"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <button
          className="w-full flex justify-center md:justify-between items-center p-2 md:px-c78.5 md:py-c17 bg-yellow4 hover:bg-yellow6 text-dark8 text-12.18 md:text-15.26 font-bold rounded-30p"
          onClick={handleSellSkin}
          disabled={isLoading}
        >
          <span>{t("sell-item")}</span>
          <div className="w-c18 h-c18 md:w-6 lg:h-6 ml-2 flex items-center justify-center">
            {isLoading ? (
              <div className="w-full h-full border-t-2 border-b-2 border-dark8 rounded-full animate-spin"></div>
            ) : (
              <Img
                src="/images/profile/cart.svg"
                alt="cart"
                className="object-contain w-full h-full"
                loader={<span className="w-full h-full" />}
              />
            )}
          </div>
        </button>
      </div>
    </ModalWrapper>
  );
};

export default SellSkinModal;
