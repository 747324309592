import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import { BottomMenuMobile } from "./BottomMenuMoble";
import { getFooterInfo } from "../../api/footer/getFooterInfo";

const TheFooter = () => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [footerInfo, setFooterInfo] = useState({
    open_cases: 0,
    total_users: 0,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFooterInfo = async () => {
      try {
        const data = await getFooterInfo();
        setFooterInfo(data);
      } catch (error) {
        console.error("Error getting footer information:", error);
      }
    };

    fetchFooterInfo();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="mt-auto footer-bottom main-mx">
      <div className="flex flex-col md:flex-row justify-evenly gap-x-16 items-start md:items-center relative lg:mb-ot-55 mb-2 bg-gradient-grey max-w-main w-full mx-auto min-h-custom-h-stat rounded-20p p-1.5 lg:p-0 mm-block-stat">
        <div className="flex lg:gap-c135 w-full lg:justify-inherit justify-around mm-footer-start">
          <div className="flex items-center">
            <Img
              src="/images/footer/open_cases.png"
              alt="Open Cases"
              width={38}
              height={38}
              className="mr-2 lg:w-c38 lg:h-c38 w-c30 h-c30"
            />
            <div>
              <p className="text-base lg:text-2xl text-white font-bold">
                {footerInfo.open_cases.toLocaleString()}
              </p>
              <p className="text-grey2 uppercase text-11 lg:text-13 font-medium">
                {t("open-cases")}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <Img
              src="/images/footer/users.png"
              alt="Users"
              width={38}
              height={38}
              className="mr-2 lg:w-c38 lg:h-c38 w-c30 h-c30"
            />
            <div>
              <p className="text-base lg:text-2xl text-white font-bold">
                {footerInfo.total_users.toLocaleString()}
              </p>
              <p className="text-grey2 uppercase text-11 lg:text-13 font-medium">
                {t("users")}
              </p>
            </div>
          </div>
        </div>
        {/* <div className="flex lg:gap-c135 w-full lg:justify-inherit justify-around ml-[-7px] lg:ml-0 mm-footer-start">
          <div className="flex items-center">
            <Img
              src="/images/footer/contracts.png"
              alt="Contracts"
              width={38}
              height={38}
              className="mr-2 lg:w-c38 lg:h-c38 w-c30 h-c30"
            />
            <div>
              <p className="text-base lg:text-2xl text-white font-bold">
                336 040
              </p>
              <p className="text-grey2 uppercase text-11 lg:text-13 font-medium">
                {t("contracts-two")}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <Img
              src="/images/footer/upgrades.png"
              alt="Upgrades"
              width={38}
              height={38}
              className="mr-2 lg:w-c38 lg:h-c38 w-c30 h-c30"
            />
            <div>
              <p className="text-base lg:text-2xl text-white font-bold">
                116 376
              </p>
              <p className="text-grey2 uppercase text-11 lg:text-13 font-medium">
                {t("upgrades")}
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <footer className="bg-footer lg:min-h-footer-h min-h-64 md:min-h-24 flex flex-col lg:justify-end justify-start items-center mm-footer">
        <div className="flex flex-col md:flex-row justify-between items-center max-w-main w-full mx-auto lg:mb-9 lg:mt-c90 mb-0">
          {/* Логотип и информация */}
          <div className="flex items-center">
            <Img
              src="/images/footer/logo-menu.svg"
              alt="Logo"
              width={132}
              height={51}
              className="mr-8 hidden lg:block"
            />
            <div className="lg:ml-4 ml-0">
              <p className="font-semibold lg:text-base text-xs">© 2024 PLG</p>
              <p className="p-footer lg:text-sm text-10 font font-normal">
                {t("copyrigth")}
              </p>
            </div>
          </div>

          {/* Иконки систем оплаты */}
          <div className="flex items-center justify-between">
            <Img
              src="/images/footer/master.png"
              alt="master"
              width={38}
              height={21}
              className="object-cover w-full min-w-c38 lg:w-c110 lg:h-15 md:w-c89 md:h-12 h-auto my-3"
            />
            {/* <Img
              src="/images/footer/qiwi.png"
              alt="qiwi"
              width={38}
              height={21}
              className="object-cover w-full min-w-c38 lg:w-c110 lg:h-15 md:w-c89 md:h-12 h-auto my-3"
            /> */}
            <Img
              src="/images/footer/visa.png"
              alt="visa"
              width={38}
              height={21}
              className="object-cover w-full min-w-c38 lg:w-c110 lg:h-15 md:w-c89 md:h-12 h-auto my-3"
            />
            <Img
              src="/images/footer/mir.png"
              alt="mir"
              width={38}
              height={21}
              className="object-cover w-full min-w-c38 w- lg:w-c110 lg:h-15 md:w-c89 md:h-12 h-auto my-3"
            />
            <button
              className="justify-center items-center w-10 h-10 cursor-pointer border border-solid border-dark5 rounded-30p hidden md:flex"
              onClick={scrollToTop}
            >
              <Img
                src="/images/footer/up.svg"
                alt="up"
                width={20}
                height={20}
              />
            </button>
          </div>
        </div>

        {/* Меню */}
        <div className="flex md:justify-between justify-center w-full max-w-main mx-auto lg:mb-16 mb-0">
          <nav className="flex items-center text-9 lg:text-sm mm-nav">
            <Link to="/support" className="lg:mr-5 mr-3.5">
              {t("support")}
            </Link>
            <Link to="/contacts" className="lg:mr-5 mr-3.5">
              {t("contacts")}
            </Link>
            <Link to="/faq" className="lg:mr-5 mr-3.5">
              FAQ
            </Link>
            <Link to="/security-policy" className="lg:mr-5 mr-3.5">
              {t("security-policy")}
            </Link>
            <Link to="/terms-of-use" className="lg:mr-5 mr-0">
              {t("terms-of-use")}
            </Link>
          </nav>
          {/* Блок с иконками соц сетей */}
          <div className="items-center space-x-4 hidden md:flex">
            <Link to="#">
              <Img
                src={
                  hoveredItem === "youtube"
                    ? "/images/footer/youtube-hover.svg"
                    : "/images/footer/youtube.svg"
                }
                alt="youtube"
                width={26}
                height={26}
                onMouseEnter={() => setHoveredItem("youtube")}
                onMouseLeave={() => setHoveredItem(null)}
              />
            </Link>
            <Link to="#">
              <Img
                src={
                  hoveredItem === "vk"
                    ? "/images/footer/vk-hover.svg"
                    : "/images/footer/vk.svg"
                }
                alt="vk"
                width={26}
                height={26}
                onMouseEnter={() => setHoveredItem("vk")}
                onMouseLeave={() => setHoveredItem(null)}
              />
            </Link>
            <Link to="#">
              <Img
                src={
                  hoveredItem === "steam"
                    ? "/images/footer/steam-hover.svg"
                    : "/images/footer/steam.svg"
                }
                alt="steam"
                width={26}
                height={26}
                onMouseEnter={() => setHoveredItem("steam")}
                onMouseLeave={() => setHoveredItem(null)}
              />
            </Link>
            <Link to="#">
              <Img
                src={
                  hoveredItem === "telegram"
                    ? "/images/footer/telegram-hover.svg"
                    : "/images/footer/telegram.svg"
                }
                alt="telegram"
                width={26}
                height={26}
                onMouseEnter={() => setHoveredItem("telegram")}
                onMouseLeave={() => setHoveredItem(null)}
              />
            </Link>
          </div>
        </div>
        <BottomMenuMobile />
      </footer>
    </div>
  );
};

export { TheFooter };
