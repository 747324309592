import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import { useModalsStore } from "../../zustand/stores/modalsStore";
import { useUserStore } from "../../zustand/stores/userStore";
import { telegramService } from "../../api/telegram/TelegramService";
import TelegramAuthModal from "../modals/TelegramAuthModal";
import { useNotification } from "../../context/NotificationContext";

const TelegramBonus: React.FC = React.memo(() => {
  const { addNotification, getTradeUrl } = useNotification();
  const { t } = useTranslation();
  const [isChecking, setIsChecking] = useState(false);

  const setIsTelegramAuthModalOpen = useModalsStore(
    (state) => state.setIsTelegramAuthModalOpen
  );

  const isTelegramAuthModalOpen = useModalsStore(
    (state) => state.isTelegramAuthModalOpen
  );

  const userData = useUserStore.use.userData();
  const isTelegramConnected = userData?.telegram_bonus?.connected ?? false;
  const isSubscribed = userData?.telegram_bonus?.received ?? false;
  const updateBalance = useUserStore((state) => state.updateBalance);
  const bonusAmount = userData?.telegram_bonus?.bonus;
  const symbol = userData?.symbol;

  const handleTelegramButtonClick = () => {
    if (!userData?.user_id || isTelegramConnected) return;
    setIsTelegramAuthModalOpen(true);
  };

  const handleSubscribeTelegram = () => {
    if (!isTelegramConnected) return;
    window.open("https://t.me/skinhousegg", "_blank");
  };

  const handleCheckAssignments = async () => {
    if (!isTelegramConnected || isChecking || !userData) return;

    setIsChecking(true);
    try {
      const response = await telegramService.checkSubscription();
      if (response.success) {
        // Обновляем баланс и данные о бонусе в одном месте
        useUserStore.getState().setUserData({
          ...userData,
          balance: response.balance, // Обновляем баланс из ответа
          telegram_bonus: {
            ...userData.telegram_bonus,
            received: true, // Обновляем статус подписки
          },
        });
      }
    } catch (error: unknown) {
      console.error("Error checking subscription:", error);
      const errorMessage =
        error instanceof Error ? error.message : t("unknown-error");
      addNotification(errorMessage, "error");
    } finally {
      setIsChecking(false);
    }
  };

  return (
    <>
      <div className="w-full lg:max-w-482 relative flex justify-between gap-x-7 bg-fon6 pt-4 lg:pt-c21 pb-6 pr-4 lg:pr-6 rounded-2xl overflow-hidden">
        <div className="w-c101 h-32 lg:w-c140 lg:h-c180 -ml-5">
          <Img
            src="/images/profile/tg.svg"
            alt="tg"
            className="object-contain w-full h-full"
            loader={<span className="w-full h-full" />}
          />
        </div>

        <Img
          src="/images/profile/tga.svg"
          alt="tga"
          width={169}
          height={154}
          className="absolute left-0 bottom-0 rounded-2xl hidden lg:block"
        />
        <Img
          src="/images/profile/tga.svg"
          alt="tga"
          width={169}
          height={154}
          className="absolute left-0 bottom-0 rounded-2xl lg:hidden"
        />

        <div className="w-full lg:w-auto">
          <p className="text-14 lg:text-20 text-white font-extrabold mb-0.5 lg:mb-0">
            {t("telegram-bonus")}
          </p>
          <p className="text-11 lg:text-13.18 text-grey4 font-medium mb-2 lg:mb-c14">
            {t("get-a")}{" "}
            <span className="text-yellow4">
              {symbol}
              {bonusAmount}
            </span>{" "}
            {t("bonus-by-completing")}
          </p>

          <button
            className={`w-full lg:min-w-72 flex justify-between items-center max-h-c30 py-1.5 lg:py-c9 pr-1.5 lg:pr-c15 pl-5 mb-c3 hover:bg-blue2 ${
              isTelegramConnected
                ? "text-grey4 bg-blue2"
                : "text-grey5 bg-blue1"
            } text-11 lg:text-13.18 font-medium rounded-42p`}
            onClick={handleTelegramButtonClick}
            disabled={isTelegramConnected}
          >
            {isTelegramConnected
              ? t("telegram-connected")
              : t("connect-telegram")}
            <div className="tg-checkbox">
              {isTelegramConnected && (
                <Img
                  src="/images/profile/check.svg"
                  alt="Connected"
                  className="w-full h-full"
                  loader={<span className="w-full h-full" />}
                />
              )}
            </div>
          </button>

          <button
            className={`w-full lg:min-w-72 flex justify-between items-center max-h-c30 py-1.5 lg:py-c9 pr-1.5 lg:pr-c15 pl-5 mb-2 lg:mb-3 hover:bg-blue2 ${
              isSubscribed ? "text-grey4 bg-blue2" : "text-grey5 bg-blue1"
            } text-11 lg:text-13.18 font-medium rounded-42p`}
            onClick={handleSubscribeTelegram}
            disabled={!isTelegramConnected}
          >
            {t("subscribe-telegram")}
            <div className="tg-checkbox">
              {isSubscribed && (
                <Img
                  src="/images/profile/check.svg"
                  alt="Subscribed"
                  className="w-full h-full"
                  loader={<span className="w-full h-full" />}
                />
              )}
            </div>
          </button>

          <button
            className={`w-full lg:min-w-72 flex justify-center items-center max-h-c30 px-5 py-2 lg:py-c13 
              ${isChecking ? "bg-grey3" : "bg-grey4 hover:bg-grey5"} 
              text-dark3 text-12 lg:text-13.20 font-bold rounded-30p
              ${!isTelegramConnected ? "cursor-not-allowed" : ""}`}
            onClick={handleCheckAssignments}
            disabled={!isTelegramConnected || isChecking}
          >
            {t("check-assignments")}
            <div className="inline-block w-c18 h-c18 lg:w-5 lg:h-5 ml-1.5">
              {isChecking ? (
                <div className="w-5 h-5 border-t-2 border-b-2 border-gray-200 rounded-full animate-spin"></div>
              ) : (
                <Img
                  src="/images/profile/save.svg"
                  alt="save"
                  className="object-cover w-full h-full"
                  loader={
                    <span className="inline-block w-full h-full ml-1.5" />
                  }
                />
              )}
            </div>
          </button>
        </div>
      </div>
      {isTelegramAuthModalOpen && <TelegramAuthModal />}
    </>
  );
});

export default TelegramBonus;
