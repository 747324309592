import { useState, useCallback, useRef, useEffect } from "react"; // Импортируем необходимые хуки из React
import { SkinTypeSlider } from "../../../utils/types"; // Импортируем типы скинов
import SliderVertical from "./SliderVertical"; // Импортируем компонент вертикального слайдера
import { sellSkin } from "../../../api/skins/apiSellSkin"; // Импортируем функцию для продажи скинов
import { useNotification } from "../../../context/NotificationContext"; // Импортируем контекст для уведомлений
import { useUserStore } from "../../../zustand/stores/userStore"; // Импортируем хранилище пользователя
import { playSound } from "../../../zustand/stores/soundStore";

// Определяем интерфейс для свойств компонента MultiSliderBlock
interface MultiSliderBlockProps {
  count: number; // Количество слайдеров
  skins: SkinTypeSlider[]; // Массив скинов
  winSkins: { skin: string; price: string; id: number }[];
  onSpinEnd: () => void; // Функция обратного вызова при окончании спина
  isFastMode: boolean; // Режим быстрой игры
  handleTryAgain: () => void; // Функция для повторной попытки
}

// Главный компонент MultiSliderBlock
export default function MultiSliderBlock({
  count, // Количество слайдеров
  skins, // Массив скинов
  winSkins, // Идентификаторы выигранных скинов
  onSpinEnd, // Функция для обработки окончания спина
  isFastMode, // Флаг режима быстрой игры
  handleTryAgain, // Функция для повторной попытки
}: MultiSliderBlockProps) {
  const [soldSkins, setSoldSkins] = useState<Set<number>>(new Set());
  const { addNotification } = useNotification(); // Получаем функцию для добавления уведомлений
  const updateBalance = useUserStore((state) => state.updateBalance); // Получаем функцию для обновления баланса пользователя

  const finishedCountRef = useRef(0); // Счётчик завершенных анимаций
  const [allFinished, setAllFinished] = useState(false); // Флаг для завершения всех анимаций

  // Звук запуска анимации воспроизводится один раз при старте
  useEffect(() => {
    if (!isFastMode) {
      playSound.startVert(); // Воспроизводим звук только в обычном режиме
    }
  }, []); // Вызовется только один раз при монтировании компонента

  const handleSliderEnd = useCallback(() => {
    finishedCountRef.current += 1;
    if (finishedCountRef.current === count) {
      playSound.winVert();
      setAllFinished(true); // Устанавливаем флаг завершения всех анимаций
      onSpinEnd();
    }
  }, [count]);

  // Функция для продажи скина по его идентификатору
  const handleSellSkin = async (skinId: number) => {
    try {
      const response = await sellSkin([skinId]); // Запрос на продажу скина
      if (response.success) {
        updateBalance(response.new_balance); // Обновляем баланс пользователя
        setSoldSkins((prevSoldSkins) => {
          const updatedSoldSkins = new Set(prevSoldSkins).add(skinId); // Добавляем проданный скин в сет
          // Если все скины проданы, вызываем функцию для повторной попытки
          if (updatedSoldSkins.size === winSkins.length) {
            handleTryAgain();
          }
          return updatedSoldSkins; // Возвращаем обновленный сет проданных скинов
        });
      }
    } catch (error: unknown) {
      // Обработка ошибок при продаже скина
      if (error instanceof Error) {
        console.error("Error selling skin:", error); // Логируем ошибку
        addNotification(
          error.message || "An unexpected error occurred", // Показываем уведомление об ошибке
          "error"
        );
      }
    }
  };

  return (
    <div className="relative">
      <div className="relative w-full flex items-center justify-evenly bg-dark1 overflow-hidden rounded-20p">
        {[...Array(count)].map((_, index) => (
          <SliderVertical
            key={`${index}-slider`}
            onSpinEnd={handleSliderEnd} // Функция для обработки окончания спина
            uniqueId={index} // Уникальный идентификатор слайдера
            isFastMode={isFastMode} // Флаг режима быстрой игры
            onSellSkin={handleSellSkin} // Функция для продажи скина
            isSold={(skinId) => soldSkins.has(skinId)} // Проверка, был ли скин продан
            winSkin={winSkins[index]}
            skins={skins}
            isAllSlidersFinished={allFinished}
            rouletteCount={count}
          />
        ))}
      </div>
      <div className="arrow-left"></div>
      <div className="arrow-right"></div>
    </div>
  );
}
