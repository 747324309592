import React from "react";
import { useSoundStore } from "../../zustand/stores/soundStore";

export const Sound: React.FC<{ className?: string }> = ({ className }) => {
  const { isMuted, toggleMute } = useSoundStore();

  return (
    <div className={`relative ${className}`}>
      <img
        src={
          !isMuted
            ? "/images/panel-header/sound-on.svg"
            : "/images/panel-header/sound-off.svg"
        }
        alt="sound"
        width={20}
        height={20}
        className="cursor-pointer"
        onClick={toggleMute}
      />
    </div>
  );
};
