// utils/truncateByRouletteCount.ts

const LENGTH_CONFIG = {
  desktop: {
    1: 20, // для одной рулетки
    2: 70, // для двух рулеток
    3: 46, // для трех рулеток
    4: 35, // для четырех рулеток
    5: 29, // для пяти рулеток
  },
  mobile: {
    1: 30, // более короткие значения для мобильных
    2: 28,
    3: 14,
    4: 10,
    5: 9,
  },
} as const;

/**
 * Обрезает текст в зависимости от количества рулеток и размера экрана
 * @param text - Текст для обрезки
 * @param rouletteCount - Количество рулеток (1-5)
 * @param isMobile - Флаг мобильного устройства
 */
const truncateByRouletteCount = (
  text: string | undefined | null,
  rouletteCount: number,
  isMobile: boolean = false
): string => {
  if (!text) return "";

  const config = isMobile ? LENGTH_CONFIG.mobile : LENGTH_CONFIG.desktop;
  const maxLength = config[rouletteCount as keyof typeof config] || 29;

  return text.length <= maxLength ? text : `${text.slice(0, maxLength)}...`;
};

export default truncateByRouletteCount;
