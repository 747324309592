import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useUserStore } from "../../../zustand/stores/userStore";

export const usePaymentLogic = (
  initialMin: number,
  rate: number,
  isSameCurrency: boolean,
  isRubToUsd: boolean,
  isCardsMethod: boolean,
  isCryptoMethod: boolean = false,
  minDepositUsd?: number,
  minDepositToken?: number
) => {
  const symbol = useUserStore.use.getSymbol()();
  const roundToNineDecimals = (num: number): number => {
    return Number(num.toFixed(9));
  };

  // Единая логика конвертации для всех случаев
  const convertAmount = (amount: number, toUsd: boolean): number => {
    // Для крипты (rate здесь это цена одной монеты в USD)
    if (isCryptoMethod) {
      return toUsd
        ? roundToNineDecimals(amount * rate)
        : roundToNineDecimals(amount / rate);
    }

    // Для карт
    if (isCardsMethod) {
      // Если карты НЕ в рублях
      if (symbol === "₽" && !isSameCurrency) {
        return toUsd
          ? roundToNineDecimals(amount * rate)
          : roundToNineDecimals(amount / rate);
      } else {
        return toUsd
          ? roundToNineDecimals(amount / rate)
          : roundToNineDecimals(amount * rate);
      }

      // Для карт в рублях
      return toUsd
        ? roundToNineDecimals(amount / rate) // В USD: делим
        : roundToNineDecimals(amount * rate); // Из USD: умножаем
    }

    // Для остальных методов
    if (isSameCurrency) return amount;
    return toUsd
      ? roundToNineDecimals(amount / rate)
      : roundToNineDecimals(amount * rate);
  };

  const [depositAmount, setDepositAmount] = useState<number | null>(
    isCryptoMethod ? minDepositToken || initialMin : initialMin
  );

  const [giftCode, setGiftCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // Используем convertAmount при инициализации
  const [getUsdAmount, setGetUsdAmount] = useState<number | null>(() => {
    // console.log("minDepositUsd:", minDepositUsd);
    if (isCryptoMethod) {
      return minDepositUsd || convertAmount(initialMin, true);
    }
    // Для карт НЕ в рублях
    if (isCardsMethod && !isRubToUsd) {
      // Если на сайте рубли - умножаем
      if (symbol === "₽" && !isSameCurrency) {
        return roundToNineDecimals(initialMin * rate);
      }
      // Если на сайте доллары - делим
      return roundToNineDecimals(initialMin / rate);
    }
    return convertAmount(initialMin, true);
  });

  useEffect(() => {
    if (isCryptoMethod && minDepositToken && minDepositUsd) {
      setDepositAmount(minDepositToken);
      if (symbol === "₽") {
        setGetUsdAmount(minDepositToken * rate);
      } else {
        setGetUsdAmount(minDepositToken * rate);
      }
    } else {
      setDepositAmount(initialMin);
      // То же самое и в useEffect
      if (isCardsMethod && !isRubToUsd) {
        if (symbol === "₽" && !isSameCurrency) {
          setGetUsdAmount(roundToNineDecimals(initialMin * rate));
        } else {
          setGetUsdAmount(roundToNineDecimals(initialMin / rate));
        }
      } else {
        setGetUsdAmount(convertAmount(initialMin, true));
      }
    }
  }, [
    initialMin,
    rate,
    minDepositToken,
    minDepositUsd,
    isCryptoMethod,
    isCardsMethod,
    isRubToUsd,
    isSameCurrency,
  ]);

  // Используем convertAmount в обработчиках
  const handleDepositChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "" ? null : Number(event.target.value);
    setDepositAmount(value);
    if (value !== null) {
      setGetUsdAmount(convertAmount(value, true));
    } else {
      setGetUsdAmount(null);
    }
  };

  const handleGetUsdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === "" ? null : Number(event.target.value);
    setGetUsdAmount(value);
    if (value !== null) {
      setDepositAmount(convertAmount(value, false));
    } else {
      setDepositAmount(null);
    }
  };

  const handleGiftCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGiftCode(event.target.value);
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  const isDepositBelowMin = useMemo(() => {
    if (isCryptoMethod && minDepositToken !== undefined) {
      return depositAmount === null || depositAmount < minDepositToken;
    }
    return depositAmount === null || depositAmount < initialMin;
  }, [depositAmount, initialMin, isCryptoMethod, minDepositToken]);

  const isUsdBelowMin = useMemo(() => {
    if (isCryptoMethod && minDepositUsd !== undefined) {
      return getUsdAmount === null || getUsdAmount < minDepositUsd;
    }
    if (!isCardsMethod) {
      const minInUsd = convertAmount(initialMin, true);
      return getUsdAmount === null || getUsdAmount < minInUsd;
    }
    return false;
  }, [
    getUsdAmount,
    initialMin,
    isCardsMethod,
    isCryptoMethod,
    minDepositUsd,
    convertAmount,
  ]);

  const validGiftCodes = useMemo(
    () => ["1", "3", "5", "10", "20", "30", "50", "100"],
    []
  );

  const isGiftCodeValid = useMemo(
    () => validGiftCodes.includes(giftCode),
    [giftCode, validGiftCodes]
  );

  const isGiftCardButtonActive = isGiftCodeValid;
  const isWalletsButtonActive = phoneNumber.trim() !== "";

  const shouldShowUsd = isCardsMethod || isCryptoMethod;

  return {
    depositAmount,
    getUsdAmount,
    giftCode,
    phoneNumber,
    handleDepositChange,
    handleGetUsdChange,
    handleGiftCodeChange,
    handlePhoneNumberChange,
    isGiftCardButtonActive,
    isWalletsButtonActive,
    isDepositBelowMin,
    isUsdBelowMin,
    shouldShowUsd,
  };
};
