import { useTranslation } from "react-i18next";
import { Img } from "react-image";
import React from "react";
import UserStatProfile from "./UserStatProfile";
import { UserProfileInfo } from "../../utils/types";

interface UserProfileProps {
  userInfo: UserProfileInfo;
}

const UserProfile: React.FC<UserProfileProps> = React.memo(({ userInfo }) => {
  const { t } = useTranslation();

  return (
    <>
      <p className="text-14 lg:text-24 text-white mb-2.5 md:mb-4">
        {t("profile")}
      </p>
      <section className="w-full flex flex-col sm:flex-row gap-3 items-center rounded-2xl mb-6">
        <div className="w-full md:w-auto flex items-center justify-center bg-dark1 rounded-14p py-4 md:py-7 pl-6 pr-c52">
          <Img
            src={userInfo.user_avatar}
            alt="avatar"
            width={56}
            height={56}
            className="mr-4 rounded-full"
          />
          <p className="text-15 text-grey5 font-medium mr-2">
            {userInfo.username}
          </p>
          <Img
            src="/images/profile/steam.svg"
            alt="steam"
            width={24}
            height={24}
          />
        </div>
        <UserStatProfile
          casesOpened={userInfo.cases_count}
          withdrawnToSteam={userInfo.skins_count_steam}
          skinsDropped={userInfo.skin_count}
        />
      </section>
    </>
  );
});

export default UserProfile;
