// src/components/ReamazeSupport/ReamazeSupport.tsx
import { useEffect } from "react";

declare global {
  interface Window {
    _support: any;
  }
}

export const ReamazeSupport = () => {
  useEffect(() => {
    // Список продакшен доменов
    const PRODUCTION_DOMAINS = ["skinhouse.gg"];

    // Проверяем, находимся ли мы на продакшен домене
    const isProduction = PRODUCTION_DOMAINS.includes(window.location.hostname);

    // Если не продакшен - не инициализируем скрипт
    if (!isProduction) {
      console.debug(
        "Reamaze support widget is disabled for non-production environment"
      );
      return;
    }
    // Инициализация _support
    window._support = window._support || { ui: {}, user: {} };
    window._support["account"] = "skinhouse";
    window._support["ui"]["contactMode"] = "mixed";
    window._support["ui"]["enableKb"] = "true";
    window._support["ui"]["styles"] = {
      widgetColor: "rgba(16, 162, 197, 1)",
      gradient: true,
    };
    window._support["ui"]["shoutboxFacesMode"] = "default";
    window._support["ui"]["shoutboxHeaderLogo"] = true;
    window._support["ui"]["widget"] = {
      displayOn: "all",
      fontSize: "default",
      allowBotProcessing: true,
      slug: "skinhouse-chat-slash-contact-form-shoutbox",
      label: {
        text: "Let us know if you have any questions! &#128522;",
        mode: "notification",
        delay: 3,
        duration: 30,
        primary: "I have a question",
        secondary: "No, thanks",
        sound: true,
      },
      position: "bottom-right",
      mobilePosition: "bottom-right",
    };
    window._support["apps"] = {
      faq: { enabled: true },
      recentConversations: {},
      orders: {},
      shopper: {},
    };

    // Загрузка скрипта Reamaze
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://cdn.reamaze.com/assets/reamaze-loader.js";
    document.head.appendChild(script);

    return () => {
      if (document.head.contains(script)) {
        document.head.removeChild(script);
      }
    };
  }, []);

  return null;
};
