import React, { useState } from "react";
import { useNotification } from "../context/NotificationContext";
import { useTranslation } from "react-i18next";

const Notification: React.FC = () => {
  const { notifications, removeNotification } = useNotification();
  const { t } = useTranslation();
  const [animating, setAnimating] = useState<number | null>(null);

  const handleNotificationClick = (notificationId: number) => {
    handleRemoveNotification(notificationId);
  };

  const handleTradeButtonClick = (
    e: React.MouseEvent,
    notificationId: number,
    tradeUrl?: string
  ) => {
    e.stopPropagation();
    if (tradeUrl) {
      window.open(tradeUrl, "_blank");
      handleRemoveNotification(notificationId);
    }
  };

  const handleRemoveNotification = (id: number) => {
    setAnimating(id);
    setTimeout(() => {
      removeNotification(id);
    }, 300);
  };

  return (
    <div className="fixed top-0.5 right-0.5 md:top-5 md:right-5 flex flex-col items-end z-50">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={`flex items-start cursor-pointer
                      bg-[#1E1E1E] border-2 
                      ${
                        notification.type === "success"
                          ? "border-green-500"
                          : "border-red-500"
                      }
                      p-3 rounded-lg text-white 
                      w-[300px] md:w-[360px] 
                      mt-1 shadow-md
                      transform transition-all duration-300 
                      ${
                        animating === notification.id
                          ? "translate-x-full opacity-0"
                          : "opacity-100"
                      }`}
          onClick={() => handleNotificationClick(notification.id)}
        >
          {notification.image ? (
            <>
              <img
                src={notification.image}
                alt="Notification"
                className="w-[45px] md:w-[90px] h-[45px] md:h-[90px] mr-2 md:mr-2 object-cover rounded flex-shrink-0"
              />
              <div className="flex flex-col justify-center flex-1 min-h-[90px]">
                <div className="text-13">
                  <span className="text-cases-name">
                    {t("item-ready-for-withdrawal")}
                  </span>{" "}
                  |{" "}
                  <span className="my-1 text-white flex-grow">
                    {notification.message}
                  </span>
                </div>
                {notification.tradeUrl && (
                  <button
                    onClick={(e) =>
                      handleTradeButtonClick(
                        e,
                        notification.id,
                        notification.tradeUrl
                      )
                    }
                    className="bg-green3 hover:bg-green4 text-dark8 font-bold py-2 px-3 text-sm rounded cursor-pointer mt-1 w-full"
                  >
                    {t("accept-trade")}
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="flex items-start w-full">
              <div className="flex-shrink-0 mr-3">
                {notification.type === "success" ? (
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6 text-red-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </div>
              <div className="flex-grow">{notification.message}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Notification;
