import api from "../api";
import { SkinType } from "../../utils/types";

interface ExchangeSkinResponse {
  success: boolean;
  balance: number;
  new_skin: SkinType; // Обновлено для использования данных от сервера.
}

export async function exchangeSkin(
  selectedSkinId: number,
  currentSkinId: number
): Promise<ExchangeSkinResponse> {
  const response = await api.request<ExchangeSkinResponse>(
    "skins/exchange/process",
    {
      method: "POST",
      data: {
        user_skin_id: currentSkinId,
        new_skin_id: selectedSkinId,
      },
    }
  );

  // Возвращаем реальные данные с сервера
  return response;
}
