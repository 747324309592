// src/providers/SoundInitializer.tsx
import React, { useEffect } from "react";
import { useSoundStore } from "../../zustand/stores/soundStore";

export const SoundInitializer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { initializeAudio, isInitialized } = useSoundStore();

  useEffect(() => {
    const handleFirstInteraction = () => {
      if (!isInitialized) {
        initializeAudio();
        // Удаляем слушатель после первой инициализации
        document.removeEventListener("click", handleFirstInteraction);
        document.removeEventListener("touchstart", handleFirstInteraction);
      }
    };

    document.addEventListener("click", handleFirstInteraction);
    document.addEventListener("touchstart", handleFirstInteraction);

    return () => {
      document.removeEventListener("click", handleFirstInteraction);
      document.removeEventListener("touchstart", handleFirstInteraction);
    };
  }, [initializeAudio, isInitialized]);

  return <>{children}</>;
};
