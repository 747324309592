import { logout } from "../api/user/apiLogout";

const protectedRoutes = ["/profile", "/deposit"]; // Здесь указать защищенные маршруты

export const isProtectedRoute = (pathname: string) => {
  return protectedRoutes.includes(pathname);
};

export const userLogout = async (
  pathname: string,
  setNeedsRedirect: (value: boolean) => void,
  reset: () => void,
  navigate: (path: string) => void
) => {
  try {
    if (isProtectedRoute(pathname)) {
      setNeedsRedirect(true);
    }
    await logout();
    reset();
    if (isProtectedRoute(pathname)) {
      navigate("/"); // Перенаправить на главную страницу
    }
  } catch (error) {
    console.error("Ошибка при разлогинивании:", error);
  }
};
