const config = {
  API_URL: process.env.REACT_APP_BASE_URL,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
};

if (!config.API_URL || !config.SOCKET_URL) {
  throw new Error(
    "Missing required environment variables. Please check your .env file."
  );
}

export default config;
