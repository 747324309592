import { io, Socket } from "socket.io-client";
import config from "../config/config";

class SocketService {
  private socket: Socket | null = null;

  private getTokenFromCookie(): string | null {
    const cookies = document.cookie.split(";");
    for (let cookie of cookies) {
      const [name, value] = cookie.trim().split("=");
      if (name === "auth_token") {
        return value;
      }
    }
    return null;
  }

  connect(): void {
    if (!this.socket) {
      // console.log(
      //   `Подключение к серверу WebSocket по адресу ${config.SOCKET_URL}`
      // );

      const token = this.getTokenFromCookie();
      const options = {
        query: token ? { token } : undefined,
      };

      this.socket = io(config.SOCKET_URL as string, options);

      this.socket.on("connect", () => {
        // console.log("Соединение с сервером WebSocket установлено");
      });

      this.socket.on("disconnect", (reason) => {
        // console.log(`Отключено от сервера WebSocket. Причина: ${reason}`);
      });

      this.socket.on("connect_error", (error) => {
        console.error("Ошибка подключения WebSocket:", error);
      });

      this.socket.onAny((eventName, ...args) => {
        // console.log(`Получено WebSocket событие "${eventName}":`, args);
      });
    }
  }

  disconnect(): void {
    if (this.socket) {
      // console.log("Отключение от сервера WebSocket");
      this.socket.disconnect();
      this.socket = null;
    }
  }

  on<T>(event: string, callback: (data: T) => void): void {
    if (this.socket) {
      // console.log(`Подписка на событие: ${event}`);
      this.socket.on(event, (data: T) => {
        // console.log(`Получены данные для события "${event}":`, data);
        callback(data);
      });
    } else {
      console.warn("Сокет не подключен. Сначала вызовите метод connect().");
    }
  }

  off(event: string, callback?: (...args: any[]) => void): void {
    if (this.socket) {
      // console.log(`Отписка от события: ${event}`);
      this.socket.off(event, callback);
    } else {
      console.warn("Сокет не подключен. Сначала вызовите метод connect().");
    }
  }

  emit<T>(event: string, data: T): void {
    if (this.socket) {
      // console.log(`Отправка события "${event}" с данными:`, data);
      this.socket.emit(event, data);
    } else {
      console.warn("Сокет не подключен. Сначала вызовите метод connect().");
    }
  }

  isConnected(): boolean {
    const connected = this.socket?.connected || false;
    // console.log(
    //   `Статус соединения WebSocket: ${connected ? "Подключено" : "Отключено"}`
    // );
    return connected;
  }
}

export const socketService = new SocketService();
