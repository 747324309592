import { LiveSkin } from "../../utils/types";
import api from "../api";

export interface LiveSkinsResponse {
  success: boolean;
  LIVE: LiveSkin[];
  BEST: LiveSkin[];
}

export async function fetchLiveSkins(): Promise<LiveSkinsResponse> {
  return api.request<LiveSkinsResponse>("info/live");
}
