import api from "../api";

interface CardsDepositResponse {
  success: boolean;
  link: string;
}

// Добавляем параметр type в функцию
export const CardsDeposit = async (
  amount: number,
  processLink: string,
  type: string
): Promise<string> => {
  const response = await api.request<CardsDepositResponse>(processLink, {
    method: "POST",
    data: {
      type: type, // Используем переданный параметр type
      amount: amount,
    },
  });

  return response.link;
};
