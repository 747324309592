import React, { useState, useEffect, useRef } from "react";
import { Img } from "react-image";
import i18n from "../../i18n";
import Cookies from "js-cookie";

const Language: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<"en" | "ru">("ru");
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLanguage = Cookies.get("selectedLanguage");
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage as "en" | "ru");
        setSelectedLanguage(storedLanguage as "en" | "ru");
      }
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectLanguage = (language: "en" | "ru") => {
    setSelectedLanguage(language);
    Cookies.set("selectedLanguage", language);
    i18n.changeLanguage(language);
    setIsDropdownOpen(false);
    window.location.reload();
  };

  return (
    <div
      ref={dropdownRef}
      className="inline-block border border-solid border-dark7 rounded-30p md:py-3 py-2 pl-3 pr-2.5 cursor-pointer relative"
      onClick={toggleDropdown}
    >
      <div className="flex items-center">
        <Img
          src={`/images/panel-header/${selectedLanguage}.svg`}
          alt={selectedLanguage}
          width={22}
          height={16}
          loader={
            <span
              style={{
                width: 22,
                height: 16,
              }}
              className="inline-block mr-2"
            />
          }
          className="inline-block mr-2"
        />
        <span className="text-12 font-medium">
          {selectedLanguage.toUpperCase()}
        </span>
        <svg
          className={`w-4 h-4 ml-2 fill-current ${
            isDropdownOpen ? "rotate-180" : ""
          }`}
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.0509 4C2.51393 4 2.22694 4.63245 2.58054 5.03656L5.34145 8.1919C5.69006 8.59031 6.30985 8.59031 6.65846 8.1919L9.41938 5.03657C9.77297 4.63245 9.48599 4 8.94901 4H3.0509Z"
            fill="#9B9FB7"
          />
        </svg>
      </div>
      {isDropdownOpen && (
        <div className="absolute md:mt-2 -mt-20 -ml-2.5 w-c89 bg-fon3 shadow-lg rounded-md border border-dark7">
          <div
            className="flex text-13 py-1 px-2 cursor-pointer hover:bg-grey1"
            onClick={() => selectLanguage("en")}
          >
            <Img
              src="/images/panel-header/en.svg"
              alt="en"
              width={22}
              height={16}
              className="inline-block mr-2"
            />
            EN
          </div>
          <div
            className="flex text-13 py-1 px-2 cursor-pointer hover:bg-grey1"
            onClick={() => selectLanguage("ru")}
          >
            <Img
              src="/images/panel-header/ru.svg"
              alt="ru"
              width={22}
              height={16}
              className="inline-block mr-2"
            />
            RU
          </div>
        </div>
      )}
    </div>
  );
};

export { Language };
