import "./case.css";
import CaseContent from "../../../components/cases/CaseContent";
import CaseTop from "../../../components/cases/CaseTop";
import { CaseTypeSlider, SkinTypeSlider } from "../../../utils/types";
import { getSkinsForCase } from "../../../api/cases/apiGetSkinsForCase";
import { useNotification } from "../../../context/NotificationContext";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sortSkinsByRarity } from "../../../utils/sortSkinsByRarity";
import { useUserStore } from "../../../zustand/stores/userStore";

export default function Cases() {
  const { name = "" } = useParams<{ name?: string }>();
  const { addNotification } = useNotification();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [skins, setSkins] = useState<SkinTypeSlider[]>([]);
  const [sortedContentSkins, setSortedContentSkins] = useState<
    SkinTypeSlider[]
  >([]); // Добавляем отдельное состояние для отсортированных скинов в CaseContent
  const [caseName, setCaseName] = useState<string>("");
  const [caseImg, setCaseImg] = useState<string>("");
  const [priceCase, setPriceCase] = useState<string>("");
  const [missingBalance, setMissingBalance] = useState<number>(0);
  const [activeAmounts, setActiveAmounts] = useState<number[]>([]);

  const isLoggedIn = useUserStore.use.isLoggedIn();
  const balance = useUserStore((state) => state.getBalance());

  const currentCases = 380; // Симуляция
  const maxCases = 1000; // Симуляция

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [name]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const caseData: CaseTypeSlider = await getSkinsForCase(name);

        if (caseData.success === true) {
          // Для рулетки используем несортированные скины
          setSkins(caseData.data.skins);

          // Для контента используем отсортированные скины
          setSortedContentSkins(sortSkinsByRarity(caseData.data.skins));

          setCaseName(caseData.data.case_name);
          setCaseImg(caseData.data.case_image);
          const price = caseData.data.case_price || "0";
          setPriceCase(price);
          setMissingBalance(caseData.data.missing_balance ?? 0);
        } else {
          addNotification("Кейс не найден", "error");
          navigate("/non-existent-case", { replace: true });
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Error retrieving case data:", error);
          const errorMessage = error.message || "Error retrieving case data -1";
          addNotification(errorMessage, "error");
        } else {
          console.error("Error retrieving case data -2:", error);
        }
        navigate("/non-existent-case", { replace: true });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [name, balance, isLoggedIn, addNotification, navigate]);

  useEffect(() => {
    const priceNumber = parseFloat(priceCase) || 0;
    if (isLoggedIn && balance >= priceNumber) {
      const amounts = [1, 2, 3, 4, 5].filter(
        (amount) => balance >= priceNumber * amount
      );
      setActiveAmounts(amounts);
    } else {
      setActiveAmounts([]);
    }
  }, [balance, priceCase, isLoggedIn]);

  const priceCaseNumber = parseFloat(priceCase) || 0;

  return (
    <>
      <section className="w-full bg-dark3.4">
        <div className="flex flex-col max-w-main mx-auto pt-c30 pb-2.5 sm:pb-10 main-mx">
          <CaseTop
            caseName={caseName}
            skins={skins}
            name={name}
            caseImg={caseImg}
            priceCase={priceCaseNumber}
            currentCases={currentCases}
            maxCases={maxCases}
            missingBalance={missingBalance}
            activeAmounts={activeAmounts}
            loading={loading}
          />
        </div>
      </section>
      <section className="w-full flex flex-col items-center max-w-main mx-auto pt-0 md:pt-c44 pb-4 sm:pb-c66 main-mx">
        <CaseContent loading={loading} skins={sortedContentSkins} />
      </section>
    </>
  );
}
