import React from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

interface AnswerProps {
  content: string;
}

const Answer: React.FC<AnswerProps> = ({ content }) => (
  <div
    className="p-7 bg-dark5 rounded-2xl text-grey4 font text-13 sm:text-sm mb-5"
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

export default function Faq() {
  const { t } = useTranslation();
  const faqData = [
    {
      question: t("question-1"),
      answer: t("answer-1"),
    },
    {
      question: t("question-2"),
      answer: t("answer-2"),
    },
    {
      question: t("question-3"),
      answer: t("answer-3"),
    },
    {
      question: t("question-4"),
      answer: t("answer-4"),
    },
    {
      question: t("question-5"),
      answer: t("answer-5"),
    },
  ];

  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const toggleQuestion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <main className="w-full flex flex-col max-w-main mx-auto pt-4 md:pt-c30 pb-4 md:pb-c66 main-mx">
      <h1 className="text-sm md:text-24 text-white">F.A.Q</h1>
      <div className="flex mt-2.5 md:mt-4 mb-2.5 md:mb-6 rounded-2xl bg-fon6 p-2.5 md:p-3 relative md:pb-8 md:max-w-main">
        <div className="flex flex-col gap-2.5">
          <h2 className="text-base md:text-2xl font-extrabold text-white">
            {t("need-help")}
          </h2>
          <p className="text-10.13 md:text-14 text-grey5 font-semibold max-w-974">
            {t("faq.description")}
          </p>
        </div>
        <Img
          src="/images/faq/pic.svg"
          alt="faq"
          width={209}
          height={214}
          className="absolute right-4 bottom-0 hidden md:block"
        />
      </div>
      {faqData.map((faq, index: number) => (
        <div
          key={index}
          className="bg-dark4 mt-1 rounded-14p px-5 text-grey4 font text-xs sm:text-sm font-semibold"
        >
          <div
            className={`py-5 cursor-pointer ${
              activeIndex === index ? "active" : ""
            }`}
            onClick={() => toggleQuestion(index)}
          >
            <div className="flex items-center gap-2.5">
              <Img
                src={
                  activeIndex === index
                    ? "/images/faq/minus.svg"
                    : "/images/faq/plus.svg"
                }
                alt="faq"
                width={24}
                height={24}
              />
              <p className="text-14">{faq.question}</p>
            </div>
          </div>
          {activeIndex === index && <Answer content={faq.answer} />}
        </div>
      ))}
    </main>
  );
}
