import { SkinWine } from "../../utils/types";
import api from "../api";

export async function getOpenCase(
  name: string,
  count: number
): Promise<SkinWine> {
  const formData = new FormData();
  formData.append("name_id", name);
  formData.append("count", count.toString());

  return api.request<SkinWine>(`case/open`, {
    method: "POST",
    data: formData,
  });
}
