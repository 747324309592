import { SkinTypeSlider } from "./types";

// Порядок редкости от худшего к лучшему
const rarityOrder: { [key: string]: number } = {
  // Серый - самый низкий уровень
  "Base Grade": 1,
  "Consumer Grade": 1,
  Default: 1,
  "базового класса": 1,
  Ширпотреб: 1,
  Стандартное: 1,

  // Светло-голубой
  "Industrial Grade": 2,
  "Промышленное качество": 2,

  // Синий
  "High Grade": 3,
  Distinguished: 3,
  "Mil-Spec Grade": 3,
  "высшего класса": 3,
  Заслуженный: 3,
  "Армейское качество": 3,

  // Фиолетовый
  Remarkable: 4,
  Exceptional: 4,
  Restricted: 4,
  "примечательного типа": 4,
  Исключительный: 4,
  Запрещённое: 4,

  // Розовый/Пурпурный
  Exotic: 5,
  Superior: 5,
  Classified: 5,
  "экзотичного вида": 5,
  Превосходный: 5,
  Засекреченное: 5,

  // Красный
  Extraordinary: 6,
  Master: 6,
  Covert: 6,
  "экстраординарного типа": 6,
  Мастерский: 6,
  Тайное: 6,

  // Золотой - самый высокий уровень
  Contraband: 7,
  Контрабанда: 7,
  Knife: 7,
  Нож: 7,
  Gloves: 7,
  Перчатки: 7,
};

export const sortSkinsByRarity = (
  skins: SkinTypeSlider[]
): SkinTypeSlider[] => {
  return [...skins].sort((a, b) => {
    const rarityA = rarityOrder[a.rarity] || 0;
    const rarityB = rarityOrder[b.rarity] || 0;
    return rarityB - rarityA;
  });
};
