import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useSpring, animated, easings } from "@react-spring/web";
import { SkinTypeSlider } from "../../../utils/types";
import { getSliderItemfon } from "../../../utils/rarityFonColor";
import bezier from "bezier-easing";
import { playSound } from "../../../zustand/stores/soundStore";
import truncateName from "../../../utils/truncateName";

const CARD_WIDTH = 210;
const CARD_HEIGHT = 280;
const SLIDER_SKINS_COUNT = 40;
const RESULT_CARD_INDEX = 30;

const calculateTranslateXFunc = () => {
  const endpoint = -(
    CARD_WIDTH * (RESULT_CARD_INDEX - SLIDER_SKINS_COUNT / 2 + 1) +
    CARD_WIDTH / 2
  );
  const randomOffset =
    Math.random() * (0.45 * CARD_WIDTH - -0.45 * CARD_WIDTH) +
    -0.45 * CARD_WIDTH;
  return { endpoint, random: randomOffset };
};

const shuffleSkins = (array: SkinTypeSlider[]): SkinTypeSlider[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

interface SliderGorizontalProps {
  skins: SkinTypeSlider[];
  onSpinEnd: () => void;
  winSkin: { skin: string; price: string; id: number };
  isFastMode: boolean;
}

const SliderGorizontal: React.FC<SliderGorizontalProps> = ({
  skins,
  onSpinEnd,
  isFastMode,
  winSkin,
}) => {
  const [sliderStatus, setSliderStatus] = useState<string>("SPINNING");
  const [winSkinData, setWinSkinData] = useState<SkinTypeSlider | null>(null);

  const visibleItems = useMemo(() => {
    let skin_shuffled = shuffleSkins(skins);
    let visibleItems = Array.from(
      { length: SLIDER_SKINS_COUNT },
      (_, i) => skin_shuffled[i % skin_shuffled.length]
    );
    let win_skin = skin_shuffled.find((item) => item.id === winSkin.skin);
    if (win_skin !== undefined) {
      visibleItems[RESULT_CARD_INDEX + 1] = win_skin;
    }
    return visibleItems;
  }, [skins, winSkin]);

  useEffect(() => {
    const win_skin = skins.find((item) => item.id === winSkin.skin);
    if (win_skin) {
      setWinSkinData({
        ...win_skin,
        price: winSkin.price.toString(),
        skin_id: winSkin.id,
      });
    }
  }, [skins, winSkin]);

  const currentPosition = useRef<number>(0);
  const calculateTranslateX = calculateTranslateXFunc();

  const scrollStyle = useSpring({
    from: {
      transform: isFastMode
        ? `translateX(${calculateTranslateX.endpoint}px)`
        : "translateX(3570px)",
    },
    to: async (next) => {
      if (sliderStatus == "FINISHED") return;

      if (isFastMode) {
        await next({
          transform: `translateX(${calculateTranslateX.endpoint}px)`,
          config: { duration: 0 },
        });
        setSliderStatus("FINISHED");
        playSound.win();
        onSpinEnd();
        return;
      } else {
        await next({
          transform: `translateX(${
            calculateTranslateX.endpoint + calculateTranslateX.random
          }px)`,
          config: { duration: 8000, easing: bezier(0.1, 0.8, 0.2, 1) },
        });
        await next({
          transform: `translateX(${calculateTranslateX.endpoint}px)`,
          config: { duration: 500, easing: easings.easeOutSine },
        });

        setSliderStatus("FINISHED");
        playSound.win();
        onSpinEnd();
      }
    },
    onChange: ({ value }) => {
      if (value && value.transform && !isFastMode) {
        const currentX = Number(value.transform.replace(/[^\d.-]/g, ""));
        const newPosition = Math.floor(currentX / CARD_WIDTH);

        if (newPosition != currentPosition.current) {
          playSound.click(); // Используем напрямую
          currentPosition.current = newPosition;
        }
      }
    },
  });

  const renderSkins = useCallback(() => {
    return visibleItems.map((skin, index) => (
      <div
        key={`${skin.id}-${index}`}
        data-id={skin.id}
        className={`card text-center relative ${
          sliderStatus == "FINISHED" && index != RESULT_CARD_INDEX + 1
            ? "dimmed-card"
            : ""
        }`}
        style={{ width: `${CARD_WIDTH}px`, flexShrink: 0 }}
      >
        {getSliderItemfon(skin.rarity)}
        <img
          style={{ zIndex: 2, marginTop: "45px", position: "relative" }}
          src={skin.image_url}
          alt={skin.skin_name}
          className="gor-img rounded mb-[5px]"
        />
        <p className="text-14 text-cases-name font-medium relative z-2">
          {skin.weapon}
        </p>
        <p className="w-48 text-16 text-cust-white font-medium text-center relative z-2 truncate">
          {skin.skin_name}
        </p>
      </div>
    ));
  }, [skins, sliderStatus]);

  return (
    <div className="relative">
      <div
        className={`roulette-wrapper relative flex justify-center w-full m-0 overflow-hidden rounded-20p bg-dark9 ${
          isFastMode ? "opacity-50" : ""
        }`}
      >
        <div
          className="selector w-[2px] bg-red8 left-1/2 h-[90%] top-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-10"
          style={{ display: sliderStatus === "SPINNING" ? "block" : "none" }}
        ></div>
        <animated.div
          className="wheel flex"
          style={{ ...scrollStyle, willChange: "transform" }}
        >
          {renderSkins()}
        </animated.div>
      </div>
      {sliderStatus == "FINISHED" &&
        winSkinData != null &&
        winSkinData.skin_id && (
          <div
            className="absolute"
            style={{
              width: `${CARD_WIDTH}px`,
              height: `${CARD_HEIGHT}px`,
              zIndex: 30,
              top: `calc(50% - ${CARD_HEIGHT / 2}px)`,
              left: `calc(50% - ${CARD_WIDTH / 2}px)`,
            }}
          >
            <div className="card text-center relative enlarged-card">
              {getSliderItemfon(winSkinData.rarity)}
              <img
                style={{ zIndex: 2, marginTop: "45px", position: "relative" }}
                src={winSkinData.image_url}
                alt={winSkinData.skin_name}
                className="gor-img rounded mb-[5px]"
              />
              <p className="text-14 text-cases-name font-medium relative z-2">
                {truncateName(winSkinData.weapon, 29)}
              </p>
              <p className="text-16 text-cust-white font-medium text-center relative px-0.5 z-2">
                {truncateName(winSkinData.skin_name, 29)}
              </p>
            </div>
          </div>
        )}
      <div className="arrow-up"></div>
      <div className="arrow-down"></div>
    </div>
  );
};

export default SliderGorizontal;
