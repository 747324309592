import { Language } from "../header/Language";
import { GamburgerMenu } from "./GamburgerMenu";
import { DepositButton } from "./DepositButton";
import { LoginBlock } from "../header/LoginBlock";
import { useUserStore } from "../../zustand/stores/userStore";
import { Sound } from "../sound/Sound";

const BottomMenuMobile = () => {
  const isLoggedIn = useUserStore.use.isLoggedIn();

  return (
    <div className="md:hidden flex justify-between w-[calc(100%-20px)] h-[66px] bg-dark4 fixed bottom-2.5 rounded-xl mx-2.5 p-4 z-20">
      <div className="flex items-center">
        <div className="mr-2">
          <Language />
        </div>
        <Sound />
      </div>
      <div className="flex">
        <div className="transition-transform duration-3000 transform origin-right">
          {isLoggedIn ? <DepositButton /> : <LoginBlock />}
        </div>
        <GamburgerMenu />
      </div>
    </div>
  );
};

export { BottomMenuMobile };
