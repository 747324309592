import api from "../api";

interface CryptoAddressParams {
  network_type: string;
  token_id?: string;
}

interface CryptoAddressResponse {
  success: boolean;
  network_type: string;
  token_id: string;
  address: string;
  tag: string | false;
}

export const getCryptoAddress = (
  params: CryptoAddressParams
): Promise<CryptoAddressResponse> => {
  return api.request<CryptoAddressResponse>("deposit/crypto/adress", {
    method: "POST",
    data: params,
  });
};
